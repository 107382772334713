<template>
    <div class="light-gray-bg">
        <svgLogo class="logo-small" />

        <module-container :question="question" :locked="!finished" :back="true" @prev="prev" @next="next">
            <template v-slot:[choiceSlot]>
                <div class="choices">
                    <div class="choice" v-for="(c, i) in question.choices" :key="i">
                        <div class="wrapper-draggable">
                            <draggable :class="{'valid': valid[i]}" :data-index="i" @touchstart="touchStart" @touchmove.prevent="touchMove" @touchend="touchEnd" @dragstart="dragStart">
                                <img :src="c.imageName" />
                            </draggable>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:[categorySlot]>
                <div class="wrapper-categories">
                    <div class="categories">
                        <div class="category" v-for="(c, i) in question.categories" :key="i">
                            <div class="wrapper-img-drop-area">
                                <div class="wrapper-drop-area" :key="i">
                                    <div class="drop-area" :data-index="i" :style="getStyleFromImageName(c.imageName)" :key="i" @drop.prevent="onDrop($event)" @dragover.prevent @dragenter.prevent>
                                        <svgDropArea class="drop-area-bg" />
                                    </div>
                                </div>
                                <img :src="c.imageName" />
                            </div>
                            <span>{{ c.text }}</span>
                        </div>
                    </div>
                </div>
            </template>

        </module-container>

    </div>
</template>

<style lang="scss">
@import '~@/scss/variables';
@import '~@/scss/fonts';

.MatchModule {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .wrapper-draggable {
        flex:1;
        position: relative;

        .draggable > * {
            max-width: 20vw;
        }
    }

    .choices {
        display: flex;
        flex: 1 0;
        margin-top: 2em;
        min-height: 15vh;

        .choice {
            flex:1;
        }
    }

    .wrapper-categories {
        display: flex;
        flex-direction: row;
        width: 100%;

        .categories {
            display: flex;
            flex: 1 0;

            .category {
                @include font-tiny(0.7);

                flex: 1;
                display: flex;
                flex-direction: column;

                .wrapper-img-drop-area {
                    position: relative;
                    flex: 1 1 10vh;
                    max-width: 25vw;

                    > img {
                        display: block;
                        margin: 0 auto;
                        width: 60%;
                    }

                    .wrapper-drop-area {
                        position: absolute;
                        height: 100%;
                        width: 60%;
                        margin: 0 auto;
                        left: 0;
                        right: 0;

                        .drop-area-bg, .drop-area, .drop-area .draggable {
                            height: 5em;
                            width: 5em;
                        }

                        .drop-area {
                            .drop-area-bg {
                                position: absolute;
                                border: none;
                                border-radius: 50%;
                                box-shadow: 1vw 1vh 1vw 0px rgba(0, 0, 0, 0.1);
                            }
                        }
                    }
                }

                span {
                    text-align: center;
                    display: block;
                }
            }
        }
    }

    // specials
    .module-right {
        .wrapper-categories {
            min-width: 10vw;

            .drop-area-bg, .wrapper-drop-area, .draggable, .wrapper-drop-area, svg, img {
                width: 100% !important;
                height: auto !important;
            }

            span {
                display: none !important;
            }
        }
    }
}
</style>

<script>
import base from './base';

import ModuleContainer from './ModuleContainer';
import Draggable from './Draggable.vue';
import { createLogger } from 'vuex';
import anime from 'animejs/lib/anime.es.js';

export default {
    name: 'MatchModule',
    extends: base(),
    data: () => ({
        initialX: 0,
        initialY: 0,
        offsetX: 0,
        offsetY: 0,
        touchX: 0,
        touchY: 0,
        draggable: null,
        dragDropType: null,
        valid: [],
    }),
    components: {
        svgLogo: require('@/assets/svg/logo.svg?inline'),
        svgDropArea: require('@/assets/svg/droparea.svg?inline'),
        ModuleContainer,
        Draggable
    },
    computed: {
        categorySlot() { return this.question.choices.length > this.question.categories.length ? 'module-right': 'default'},
        choiceSlot()   { return this.question.choices.length > this.question.categories.length ? 'default': 'module-header-right'},
        finished()     { return this.valid.includes(true); }
    },
    methods: {
        getStyleFromImageName(imageName) {
            let match = imageName.match(/(?!margin)(?:\d+)/gi);
            if (match) {
                return { margin: match.map((m) => m > 0 ? m + '%' : '0').join(' ') }
            }

            return { };
        },
        dragStart(event) {
            event.dataTransfer.dropEffect = 'move';
            event.dataTransfer.effectAllowed = 'move';

            this.dragDraggable(event, event.currentTarget);
        },
        touchStart(event) {
            this.initialX = event.touches[0].clientX - this.offsetX;
            this.initialY = event.touches[0].clientY - this.offsetY;

            if (event.currentTarget.classList.contains('draggable')) {
                this.dragDraggable(event, event.currentTarget);
            }
        },
        touchMove(event) {
            if (this.draggable) {
                this.touchX = event.touches[0].clientX;
                this.touchY = event.touches[0].clientY;

                let x = this.touchX - this.initialX;
                let y = this.touchY - this.initialY;

                this.offsetX = x;
                this.offsetY = y;

                this.draggable.style.transform = "translate3d(" + x + "px, " + y + "px, 0)";
                console.log('swet')
            }            
        },
        touchEnd() {
            if (this.draggable) {

                let dropAreas = document.getElementsByClassName('drop-area');
                let overlappingDropArea = null;

                dropAreas.forEach(da => {
                    if(!(
                        da.getBoundingClientRect().right < this.draggable.getBoundingClientRect().left || 
                        da.getBoundingClientRect().left > this.draggable.getBoundingClientRect().right || 
                        da.getBoundingClientRect().bottom < this.draggable.getBoundingClientRect().top || 
                        da.getBoundingClientRect().top > this.draggable.getBoundingClientRect().bottom
                    )) {
                        overlappingDropArea = da;
                    }
                });

                if (overlappingDropArea) {
                    this.dropDraggable(overlappingDropArea);
                } else {
                    // Move the draggable back to its original container
                    let origContainer = document.getElementsByClassName('wrapper-draggable')[this.draggable.getAttribute('data-index') ?? 0];
                    origContainer.appendChild(this.draggable);

                    this.valid = [];

                    this.resetDraggable();
                }
            }
        },
        onDrop(event) {
            let dabg = event.currentTarget;
            this.dropDraggable(dabg);
        },
        dragDraggable(event, draggable) {
            if (event.type == 'touchstart') {
                // Reset drop-areas
                let das = document.getElementsByClassName('drop-area');
                das.forEach(da => {
                    // Reset already placed draggable
                    let apd = da.getElementsByClassName('draggable')[0];
                    if (apd && apd != draggable) {
                        let origContainer = document.getElementsByClassName('wrapper-draggable')[apd.getAttribute('data-index') ?? 0];
                        origContainer.appendChild(apd);

                        this.valid = [];
                    }

                    // Reset drop area bg
                    da.getElementsByClassName('drop-area-bg')[0].style ="display:block;";
                });
            }

            this.draggable = draggable;
        },
        dropDraggable(dropArea) {
            // Reset the drop-area-bgs
            let dabgs = document.getElementsByClassName('drop-area-bg');
            dabgs.forEach(bg => {
                bg.style = 'display:block;';
            });

            // Hide the drop-area-bg
            dropArea.getElementsByClassName('drop-area-bg')[0].style ="display:none;";

            // Remove already placed draggable
            let apd = dropArea.getElementsByClassName('draggable')[0];
            if (apd) {
                let origContainer = document.getElementsByClassName('wrapper-draggable')[apd.getAttribute('data-index') ?? 0];
                origContainer.appendChild(apd);
            }

            // Append draggable to drop area
            dropArea.appendChild(this.draggable);

            // Validate the answer
            if (this.validateAnswer(dropArea)) {
                this.resetDraggable();
            }
        },
        validateAnswer(dropArea) {
            let category = this.question.categories[dropArea.getAttribute('data-index')],
                choice = this.question.choices[this.draggable.getAttribute('data-index')];

            let valid = category.index == choice.categoryIndex;
            this.$set(this.valid, this.draggable.getAttribute('data-index'), valid);

            if (valid) {
                
                return true;
            } else {

                this.draggable.style.transform = "translate3d(0px, 0px, 0)";
                dropArea.getElementsByClassName('drop-area-bg')[0].style ="display:block;";
                setTimeout(() => {

                    let origContainer = document.getElementsByClassName('wrapper-draggable')[this.draggable.getAttribute('data-index') ?? 0];

                    let from = dropArea.getBoundingClientRect();
                    let to = origContainer.getBoundingClientRect();

                    // add back to original container
                    origContainer.appendChild(this.draggable);

                    // reset dragging offsets
                    this.offsetX = this.touchX = this.offsetY = this.touchY = 0;

                    // set position on wrong drop target
                    this.draggable.style.transform = "translateX(" + (from.left - to.left) + "px) translateY(" + (from.top - to.top) + "px)";                        

                    // animate it back to original position
                    anime({
                        targets: this.draggable,
                        duration: 500,
                        translateX: 0,
                        translateY: 0,
                        easing: 'easeInOutSine'
                    })
                }, 500);
                return false;
            }
        },
        resetDraggable() {
            // Reset positioning draggable
            this.draggable.style.transform = "translate3d(0px, 0px, 0)";
            this.offsetX = this.touchX = this.offsetY = this.touchY = 0;

            this.draggable = null;
        }
    },
    mounted() {
        this.valid = this.question.choices.map(() => false);
    }
}
</script>