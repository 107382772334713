import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const store = new Vuex.Store({
	plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
	state: {
		locale: 'nl',
		retake: false,
	},
	mutations: {
		locale(state, locale) {
			state.locale = locale;
		},
		retake(state, retake) {
			state.retake = retake;
		}
	},
	getters: {
		locale: state => {
			return state.locale;
		},
		retake: state => {
			return state.retake;
		}
	}
});

export default store;