<template>
    <div :class="theme" class="test-top-bar">
        <div class="top-bar-wrapper">
            <div v-if="step !== undefined" :class="classes">
                <div class="progress-bar">
                    <div class="step-bar">
                        <div v-for="i in step.max" :key="i" class="step-point" :class="{active:step.current >= (i - 1)}"></div>
                        <div class="step-text">
                            <strong>{{ (step.current + 1) }}/{{ step.max }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import '~@/scss/variables';
@import '~@/scss/mixins';

.test-top-bar {
    width: 100vw;
    display: flex;
    position: absolute;
    z-index: 1;

    .progress-bar {
        margin: 7vh auto 0;
        display: flex;
        justify-content: center;

        .step-bar {
            // margin: 0 2vw;

            display: flex;
            flex-direction: row;
            align-items: center;

            * {
                display: inline-block;
            }

            .step-point {
                margin: 0 1vw;
                width: 3vw;
                height: 3vw;
                max-width: 1em;
                max-height: 1em;
                border: none;
                border-radius: 50%;
                background-color: white;
            }

            .step-point.active {
                background-color: $green-color;
            }

            .step-text {
                color: $blue-color;
                font-size: 0.6em;
                margin-left: 2vw;
            }
        }
    }
}

.test-top-bar.blue .progress-bar .step-bar .step-text {
    color: white;
}

</style>

<script>
export default {
    name: 'TestTopBar',

    props: {
        step: Object,
        progress: {type: Number, default: 1},
        theme: String
    },

    computed: {
        classes() {
            return { 
                'top-bar': true, 
                'transparent': this.step === undefined,
            };
        }
    },
}
</script>