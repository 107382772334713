import $router from '../routes'
import store from '../store';

const FLOW_ROUTES = Object.freeze([
    {name: 'assessment', code: 'dsm-entrytest-{locale}'},
    {name: 'assessment', code: 'dsm-interimtest-{locale}'},
    {name: 'assessment', code: 'dsm-finaltest-{locale}'},
    {name: 'assessment-result', code: 'dsm-finaltest-{locale}'},
    {name: 'assessment', code: 'dsm-finaltest-retake-{locale}'},
    {name: 'assessment-result', code: 'dsm-finaltest-retake-{locale}'},
]);

class AssessmentFlowService {

    /** 
     * The current step
     * @private
     * @type {Number}
     */
    #step = -1;

    next() {

        this.#step++;
        if (this.#step >= FLOW_ROUTES.length)
        {
            // TODO
            console.log('next done', this.#step);
            return;
        }

        let replacements = [];
        replacements['locale'] = store.getters.locale;

        const route = FLOW_ROUTES[this.#step];
        $router.push({ name: route.name, params: { code: route.code.replace(/\{(\w+)\}/ig, (_, offset) => replacements[offset] )} });
    }
}

export default new AssessmentFlowService();