<template>
    <div class="module-wrapper" :class="back ? '' : 'no-module-left-button'">
        <div v-if="back" class="module-left-button">
            <button class="btn prev circular-btn" @click.prevent="$emit('prev')">
                <lottie-animation
                    ref="anim-replay"
                    :animationData="require('@/assets/animations/back-to-video.json')"
                    :loop="false"
                    :autoPlay="true"
                />
            </button>
        </div>

        <div class="module-content-wrapper">
            <div class="module-content">
                <div class="module-header">
                    <div class="question">
                        <div class="inline-m">
                            <div class="description blue-text" v-html="question.title"></div>
                            <h3 class="title blue-text" v-html="question.text"></h3>
                        </div>

                        <div v-if="question.description" class="text blue-text" v-html="question.description"></div>
                    </div>
                    <div class="module-header-right" v-if="hasModuleHeaderRight">
                        <slot name="module-header-right"></slot>
                    </div>
                </div>

                <slot></slot>
            </div>

            <div class="module-right" v-if="hasModuleRight">
                <slot name="module-right" class="module-right"></slot>
            </div>
        </div>

        <div class="module-right-button">
            <button class="btn next circular-btn" :disabled="locked" :class="{ hidden: hidden }" @click.prevent="$emit('next')">
                <svgLock v-if="locked" class="lock" />
                <lottie-animation v-else
                    ref="anim-unlock"
                    :animationData="require('@/assets/animations/unlock.json')"
                    :loop="false"
                    :autoPlay="true"
                />
            </button>
        </div>
    </div>
</template>

<style lang="scss">
@import '~@/scss/variables';
@import '~@/scss/fonts';
@import '~@/scss/mixins';

.module-wrapper {
    display: grid;
    grid-template-areas: "left content right";
    grid-auto-columns: 1fr 7fr 1fr;
    grid-template-rows: auto;
    max-width: $container-max-width;
    padding: 0 1em;
    height: 100%;
    width: 90%;

    @include media('<medium') { 
        grid-template-areas: "content content" "left right";
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        &.no-module-left-button {
           grid-template-areas: "content" "right";
           grid-template-columns: 1fr;
           grid-template-rows: auto;
        }
    }

    .module-content-wrapper {
        grid-area: content;
        margin-top: max(15vh, 5em);
        flex: 1;
        display: flex;
        flex-direction: row;

        .module-header {
            display: flex;
            flex-direction: row;

            .question {
                flex: 1;
                flex-basis: 70%;
                padding-right: 5vw;

                .description {
                    display: inline-block;
                    padding: 0 1em 0 1em;
                    border: 0.1em solid $blue-color;
                    border-radius: 0.6em;
                    @include font-tiny(0.8);
                }
                .title {
                    text-align: left;
                    @include font-medium(1);
                    width: 60%;

                    @include media("<=medium") {
                        width: 100%;
                    }
                }
                .text {
                    @include font-base(0.7);
                }
            }

            .module-header-right {
                flex: 1 1 30%;
            }
        }

        .module-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-self: flex-start;
        }
    }

    .module-left-button, .module-right-button {
        width: 4em;
        display: flex;

        .btn.prev {
            margin: 0.2em;
            align-self: center;

            &:hover {
                svg {
                    path {
                        stroke:$grey;
                        fill: $grey;
                    }
                }
            }
        }

        .btn.next {
            margin: 0.2em;
            align-self: center;
        }
    }

    .module-left-button {
        grid-area: left;
        justify-self: center;
    }

    .module-right-button {
        grid-area: right;
        justify-self: center;
    }

    @include media('<medium') { 

        .title {
            margin: 0.5em 0;
        }

        .module-left-button {
            justify-self: self-end;
            padding-right: 1em;
            margin-bottom: 10vh;
        }
        
        .module-right-button {
            justify-self: self-start;
            padding-left: 1em;
            margin-bottom: 10vh;
        }

        &.no-module-left-button {
           .module-right-button {
                justify-self: center;
                margin-bottom: 10vh;
           }
        }
    }
}
</style>

<script>
import LottieAnimation from 'lottie-web-vue'

export default {
    name: 'ModuleContainer',
    props: {
        question: Object,
        back: { type: Boolean, default: false },
        locked: { type: Boolean, default: true },
        hidden: { type: Boolean, default: false },
    },
    computed: {
        hasModuleHeaderRight() { 
            return !!this.$slots ['module-header-right']; },
        hasModuleRight() { return !!this.$slots ['module-right']; }
    },
    components: {
        svgLock: require('@/assets/svg/lock.svg?inline'),
        svgArrowRight: require('@/assets/svg/arrowright.svg?inline'),
        LottieAnimation
    }
}
</script>