<template>
    <button ref="btn" class="btn" :class="classes" v-on="$listeners">
        <div class="btn-container">
        <div class="left" v-if="$slots.left">
            <slot name="left"></slot>
        </div>
        <div class="content">
            <slot></slot>
        </div>
        <div class="right" v-if="$slots.right">
            <slot name="right"></slot>
        </div>
        </div>
    </button>
</template>

<style lang="scss">
@import '~@/scss/fonts';
@import '~@/scss/variables';

.btn {
    --button-color: white;
    --button-border: #{$text};
    --button-background: #{$text};

    background: none;
    border: none;

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
    -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
    outline: none !important;

    color: var(--button-color);

    padding: 0;

    @include no-select;

    .btn-container {
        display: flex;
        align-items: center;

        margin: 0;
        padding: 3px;
        cursor: pointer;

        //font-weight: 600;
        $factor: 1;

        @include font-medium($factor);

        &.font-base { @include font-base($factor); }
        &.font-medium { @include font-medium($factor); }
        &.font-large { @include font-large($factor); }

        border: 1px solid var(--button-border);
        border-radius: 2em;

        background: var(--button-background);

        @include input-focus-transition;
    }

    &.add-top {
        margin: 1em;
    }

    &:focus:not(.tile ) {
        .btn-container {
            @include input-focus;
        }
    }

    &:not(.disabled):not([disabled]):not(.tile) {
        &:hover {
            .btn-container {
                transform-origin: 50% 0%;
                transform: scale(0.975);
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
            }
        }
    }

    .content {
        flex: 1 0 auto;
        padding: 0.1em 0.5em;
        text-align: center;
    }

    .left, .right {
        display: flex;
        align-items: center;
        justify-content: center;

        svg { height: 1.5em; }
    }

    &.disabled, &[disabled] {
        opacity: 0.2;
        pointer-events: none;
    }

    &.condensed {
        .content { padding: 1px }
    }

    &.inverted {
        .btn-container {
            color: var(--button-background);
            background: var(--button-color);
            border-color: var(--button-color);
        }
    }

    &.hidden {
        display: none;
        visibility: hidden;
    }
}
</style>

<script>
export default {
    name: 'Button',

    props: {
        font: String,
        condensed: Boolean,
        inverted: Boolean
    },

    computed: {
        classes() {
            return {
                'condensed': this.condensed,
                'inverted': this.inverted,
                ['font-' + this.font]: this.font
            };
        }
    },

    methods: {
        focus: function () {
            this.$refs.btn.focus()
        }
    }
}
</script>