import isNull from "../../utils/isNull";

export default class Segment {
    /** 
     * The current step
     * @private
     * @type {Number}
     */
    #step = 0;

    /**
     * The current step
     * @public
     * @type {Number}
     */
    get step() { return this.#step; }

    /**
     * The number of steps
     * @private
     * @type {Number}
     */
    #steps;

    /**
     * The number of steps
     * @public
     * @type {Number}
     */
    get steps() { return this.#steps; }

    /**
     * The search keys
     * @private
     * @type {any[]}
     */
    #keys;

    /**
     * The search keys
     * @public
     * @type {any[]}
     */
    get keys() { return this.#keys; }

    /** 
     * The label
     * @public
     * @type {String} 
     */
    label;

    /**
     * Create a basic segment
     * @param {string} label The label of the segment
     * @param {Number} steps The number of sub-progress steps
     */
    constructor(label = undefined, keys = [], steps = 1) {
        this.label = label;
        this.#keys = keys;
        this.#steps = steps;
    }

    get status() {
        return {
            label: this.label,
            steps: { current: this.step, max: this.steps }
        };
     }

    /**
     * Set step if the segment contains the key
     * @public
     * @param {any} key The key
     * @param {number} step The step
     */
    setStepByKey(key, step = 0/*, ignoreAlreadyActive = true*/) {
        if (isNull(key)) {
            return false;
            // TODO: Could short-circuited but for simplicity not now 
            // throw new Error('Key is undefined/null');
        }

        if (this.keys.includes(key)) {
            if (!this.setStep(step/*, ignoreAlreadyActive*/)) {
                return false;
                // TODO: Could short-circuited but for simplicity not now 
                // throw new Error('Active out of bounds or already active');
            }
            
            return true;
        }

        return false;
    }

    /**
     * Set the active by step
     * @public
     * @param {number} step 
     */
    setStep(step/*, ignoreAlreadyActive = true*/) {
        if (step < 0 || step >= this.steps)
            return false;

        this.#step = step;

        return true;
    }

    setStepAtBegin() {
        this.#step = 0;
    }

    setStepAtEnd() {
        this.#step = Math.max(0, this.#steps - 1);
    }

    /**
     * Progress to the next element
     * @public
     * @returns {Boolean} True if the action is handled internal. False if the action should be handled by the caller.
     */
    next() {
        return this.setStep(this.step + 1);
    }

    /**
     * Progress to the previous element
     * @public
     * @returns {Boolean} True if the action is handled internal. False if the action should be handled by the caller.
     */
    prev() {
        return this.setStep(this.step - 1);
    }
    
    *load() { }
}