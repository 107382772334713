import { SegmentRule, FunctionSegment } from '@/../packages/mia-assignment-manager';
import AssignmentSegment from './components/segments/AssignmentSegment';

export default function() {
    const moduleParsers = {
        'TextModule': (module) => ({
            text: module.text,
            skipText: module.skipText,
        }),
        'MovieplayerModule': (module) => ({
            video: module.movieFilename,
            fullscreen: module.fullscreen,
            description: module.description,
            hideControls: module.hideControls,
            backgroundImage: module.backgroundImage
        }),
        'MultipleChoiceModule': (module) => ({
            text:  module.questionText,
            image: module.questionImage,
            description: module.description,
            backgroundImage: module.backgroundImage,
            itemsPerLine: module.itemsPerLine,
            multiple: module.multiple,
            useImageAnswers: module.useImageAnswers,
            explanation: module.explanation,
            explanationMovie: module.explanation.image,
            choices: module.choices.map((choice) => ({
                id: choice.id, 
                text: choice.selectionText, 
                image: choice.answerImage, 
                isCorrect: choice.isCorrect 
            }))
        }),
        'MatchModule': (module) => ({
            text:  module.questionText,
            description: module.description,
            showSourceItemText: module.showSourceItemText,
            showSourceItemImage: module.showSourceItemImage,
            audioIntroName: module.audioIntroName,
            categories: module.categoryItems.map((category) => ({
                index: category.index,
                text: category.text,
                imageName: category.imageName
            })),
            choices: module.choices.map((choice) => ({
                id: choice.id,
                text: choice.text,
                imageName: choice.imageName,
                categoryIndex: choice.categoryIndex
            }))
        })
    };

    const segmentParsers = {
        preParse(assignment, session) {

            let segments = [];
            // console.log(assignment);

            // Show Startmodule when assignment has a topbar
            if (assignment.meta?.topbar != 'none') {
                segments.push(new AssignmentSegment({ component: 'StartModule', params: { title: assignment.title, description: assignment.description, theme: assignment.meta?.theme } }));
            }

            if (session?.status === 'open') {
                segments.push(new FunctionSegment({ type: 'session-continue' }));
            }
            else if (session?.status === 'finished' && assignment.maxNumberOfCompletions === 1) {
                segments.push(new FunctionSegment({ type: 'finished' }));
            }
			else {
            	segments.push(new FunctionSegment({ type: 'session-create' }));
            }

            return segments;
        },
        //eslint-disable-next-line no-unused-vars
        postParse(assignment, session) {

            let segments = [];

            // Show EndModule when assignment has a dots topbar
            if (assignment.meta?.topbar == 'dots') {
                segments.push(new AssignmentSegment({ component: 'EndModule' }));
            }

            segments.push(new FunctionSegment({ type: 'session-finish' }));

            return segments;
        }
    };

    const segmentRules = [
        // Base modules
        new SegmentRule({ component: 'TextModule' }).addRule('TextModule', '1'),
        new SegmentRule({ component: 'MovieplayerModule' }).addRule('MovieplayerModule', '1'),
        new SegmentRule({ component: 'MultipleChoiceModule' }).addRule('MultipleChoiceModule', '1'),
        new SegmentRule({ component: 'MatchModule' }).addRule('MatchModule', '1'),
    ];

    return { 
        parser: { moduleParsers },
        segmentParsers,
        segmentRules,
    };
}