import isNotNull from "../utils/isNotNull";

export class SegmentRuleCollection {
    /** @type {SegmentRule[]} */
    rules = [];

    /** @param {SegmentRule[]} rules */
    constructor(rules) {
        if (Array.isArray(rules)) {
            this.rules = rules;
        }
    }

    /**
     * @param {string[]} values 
     * @returns {SegmentRuleMatch?}
     */
    match(values) {
        const occurrences = {};

        for (const value of values) {
            occurrences[value] = (occurrences[value] ?? 0) + 1;
        }

        for (const rule of this.rules) {
            const match = rule.match(occurrences);
            if (match) {
                return match;
            }
        }

        return null;
    }
}

export class SegmentRule {
    /** @type {Object|Function} */ 
    #data;

    /** @type {Number|Function} */ 
    #steps = 1;

    /** @type {Rule[]} */
    rules = [];

    /** @param {Object} data */
    constructor(data) {
        this.#data = data;
    }

    /**
     * @param {Number|Function} steps 
     */
    steps(steps) {
        this.#steps = steps;
        return this;
    }

    /**
     * @param {string} module 
     * @param {'*'|'> ...'|'< ...'|'! ...'|'...'} condition 
     * @param {boolean} [optional=false]
     */
    addRule(module, condition, optional = false) {
        if (this.rules.find(r => r.match === module))
            throw 'SegmentRule already contains a rule for: ' + module;

        this.rules.push(new Rule(module, condition, optional));
        
        return this;
    }

    /**
     * @param {Object.<string, number>} occurrences 
     * @returns {SegmentRuleMatch?}
     */
    match(occurrences) {
        // If there is some occurrences that are not at all in any of the rules then it is no match
        if (Object.keys(occurrences).some(o => !this.rules.some(r => r.value === o)))
            return false;

        if (this.rules.every(r => r.match(occurrences[r.value]))) {

            const data = this.calculate(this.#data, occurrences, isNotNull, 'Invalid data');
            const steps = this.calculate(this.#steps, occurrences, Number.isSafeInteger, 'Invalid steps');

            return new SegmentRuleMatch(data, steps);
        } else {
            return null;
        }
    }

    calculate(value, occurrences, check, error) {
        const result = typeof value === 'function' ? value(occurrences) : value;

        if (!check(result)) throw new Error(error);

        return result;
    }
}

export class SegmentRuleMatch {
    /** @type {any} */
    data;

    /** @type {number} */
    steps;

    constructor(data, steps) {
        this.data = data;
        this.steps = steps;
    }
}

class Rule {
    constructor(value, condition, optional = false) {
        this.value = value;
        this.condition = String(condition).replace(/\s/g, '');
        this.optional = optional;
    }

    match(count) {
        // No matching count only matches if the rule is optional
        if (count === undefined)
            return this.optional;

        // Shorthand for '> 0'
        if (this.condition === '*')
            return count > 0;

        const [, operator, value] = this.condition.match(/^(>|<|!)?(\d)$/);
        switch (operator) {
            case '>': return count >  value;
            case '<': return count <  value;
            case '!': return count != value;
            default:  return count == value;
        }
    }
}