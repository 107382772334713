<template>
    <div :class="theme">
        <div class="bg v-align-center full-width">
            <div class="container">
                <svgLogo class="logo-small" />

                <h1 class="assignment title align-center" v-html="title"></h1>
                <div class="assignment text align-center" v-html="description"></div>

                <button condensed class="align-center btn round" @click="next">Start</button>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import "~@/scss/fonts";

.StartModule {
    width: 100%;
    display: flex;
    flex-direction: row;

    .assignment.title {
        @include font-big(0.9);
        line-height: 1em;
        margin-bottom: 0.3em;
    }

    .assignment.text {
        display: flex;
        flex-direction: column;
    }

    .btn {
        margin-top: 2em;
    }

    #columns {
        display: grid;
        grid-auto-columns: repeat(3, 1fr);
        grid-auto-rows: auto;
    }

    #age-icon {
        display: block;
    }

    #camera-icon {
        display: block;
    }

    #data-icon {
        display: block;
    }
}
</style>

<script>

import base from './base';

export default {
    name: "StartModule",
    extends: base(),
    components: {
        svgLogo: require("@/assets/svg/logo.svg?inline"),
    },
    props: {
        title: String,
        description: String,
        theme: {type: String, default: 'blue'}
    }
};
</script>