<template>
	<div class="draggable" draggable @touchstart="touchstart" @touchmove="touchmove" @touchend="touchend" @dragstart="dragstart">
        <slot></slot>
    </div>
</template>

<style lang="scss">
    @import '~@/scss/variables';

    .draggable {
        position: absolute;
        z-index: 99999;
        border: none;
    }

    .draggable > * {
        width: inherit;
        height: inherit;
        border-radius: 50%;
        box-shadow: 1vw 1vh 1vw 0px rgba(0, 0, 0, 0.1);
        max-width: 25vw;
    }

    .drop-area {
        .draggable {

            &::before {
                content: '';
                position: absolute;
                top: 10%;
                left: 10%;
                bottom: 0;
                right: 0;
                background: url(~@/assets/svg/redcross.svg) top right no-repeat;
                background-size: contain;
                width: 80%;
                height: 80%;
            }

            &.valid {
                &::before {
                    content: '';
                    background: none;
                }
            }
        }
    }
</style>

<script>
export default {
    name: 'Draggable',
    methods: {
        dragstart(event) {
            this.$emit('dragstart', event);
        },
        touchmove(event) {
            this.$emit('touchmove', event);
        },
        touchstart(event) {
            this.$emit('touchstart', event);
        },
        touchend(event) {
            this.$emit('touchend', event);
        }
    }
}
</script>