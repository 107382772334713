export default function(singleQuestion = true) {   
    const component = {
        props: {
            steps: Object,
            questions: Array,
            message: Function,
        },
        methods: {
            async next() { await this.message('next'); },
            async prev() { await this.message('prev'); },
            onStep() {}
        },
        computed: {

        },
        watch: {
            steps: {
                immediate: true,
                handler: 'onStep'
            }
        }
    };

    if (singleQuestion) {
        Object.assign(component.computed, {
            question() { return this.questions[0].question; },
            answer() { return this.questions[0].answer; },
        });
    }

    return component;
}