<template>
    <div id="app" ref="app">
        <div> 
            <!-- <transition name="slideup">
                <div class="nav-wrapper">
                    <nav role="navigation" class="nav-authenticated" v-if="isAuthenticated">
                        <div><router-link :to="{ name: 'home'}"><svgHome />home</router-link></div>
                        <div><a href="#" @click.prevent="logout('landing')"><svgLogout style="transform: rotateZ(180deg)" />log uit</a></div>
                    </nav>
                    <nav role="navigation" v-else>
                        <div><router-link :to="{ name: 'landing' }"><svgHome />home</router-link></div>
                        <div><router-link :to="{ name: 'login' }"><svgLogin />log in</router-link></div>
                    </nav>
                </div>
            </transition> -->

            <transition :name="transitionName" :mode="transitionMode" @before-leave="beforeLeave" @after-enter="afterEnter">
                <router-view class="main" role="main"/>
            </transition>
        </div>
        <!-- <footer>
            <p>footer</p>
        </footer> -->
    </div>
</template>

<script>
import Auth from './services/Auth';
import MIAConnection from '../packages/mia-connection';

const VIEWS = Object.freeze({
    'landing': {
        animation: 'landing',
        hideNavigation: false,
    },
    'pop': {
        animation: 'pop',
        hideNavigation: true,
    },
    'page': {
        animation: 'page',
        hideNavigation: false,
    },
    'page-no-nav': {
        animation: 'page',
        hideNavigation: true,
    },
    'default': {
        animation: 'default',
        hideNavigation: false,
    },
    'home': {
        animation: 'home',
        hideNavigation: false,
    }
});

export default {
    name: 'app',

    components: {
        svgHome: require('@/assets/svg/home.svg?inline'),
        svgLogin: require('@/assets/svg/login.svg?inline'),
        svgLogout: require('@/assets/svg/logout.svg?inline'),
        //svgClose: require('@/assets/svg/close.svg?inline'),
    },

    data: () => ({
        isAuthenticated: false,
        browser: undefined,

        keyboardShown: false,
        transitionMode: null,
        transitionName: null
    }),

    mounted() {
        MIAConnection.setRouter(this.$router);
    },

    watch: {
        $route: {
            immediate: true,
            handler (to, from) {

                this.isAuthenticated = Auth.isLoggedIn();

                const toView   = VIEWS[to?.meta?.view ?? 'default'];
                const fromView = VIEWS[from?.meta?.view ?? 'default'];

                this.transitionName = 'slideup';
                this.transitionMode = 'in-out';

                if ((toView.animation == 'page' && fromView.animation == 'page') ||
                    (toView.animation == 'landing')) {
                    this.transitionMode = 'out-in';
                }

                if ((toView.animation == 'default' && fromView.animation == 'page') ||
                    (toView.animation == 'page' && fromView.animation == 'default') ||
                    (toView.animation == 'default' && fromView.animation == 'default')) {

                    const toDepth = to?.path.split('/').length;
                    const fromDepth = from?.path.split('/').length;
                    this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
                    this.transitionMode = 'out-in';
                }

                // console.log(fromView.animation, toView.animation, this.transitionName, this.transitionMode);
            },
        }
    },

    methods: {
        beforeLeave() {
            document.body.style.overflowY = (this.transitionMode == 'out-in' ? 'hidden' : null);
        },
        afterEnter() {
            document.body.style.overflowY = (this.transitionMode == 'out-in' ? null : 'hidden');
        },
        logout(route) {
            Auth.logout();

            if (this.$route.name !== route) {
                this.$router.push( { name: route });
            } else {
                this.$router.go();
            }
        }
    }
}
</script>
