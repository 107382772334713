import MIAConnection from '../mia-connection';

class CourseSessionRepository {
    #sessions = {};

    async start(course, delay = (2 * 60 * 1000), initial = { category: 'session', type: 'starting', parameter: undefined }) {
        if (initial == null) throw new Error('Initial event must be given');

        // Stop a session if it is already started
        this.stop(course);

        // Create a session manager
        const session = new CourseSessionManager(course, delay);

        // Submit initial event to create the session in mia
        await session.send(initial);

        // Return the session manager
        return this.#sessions[course] = session;
    }

    get(course) {
        return this.#sessions[course];
    }

    stop(course) {
        this.#sessions[course]?.finalize();

        delete this.#sessions[course];
    }
}

export class CourseSessionManager {
    #course;
    #interval;

    constructor(course, delay) {
        this.#course = course;
        this.#interval = setInterval(() => MIAConnection.postCourseSessionKeepAlive(course), delay);
    }

    async send({ category, type, parameter }) {
        await MIAConnection.postCourseSessionEvent(this.#course, category, type, parameter);
    }

    finalize() {
        clearInterval(this.#interval);
    }
}

export default new CourseSessionRepository();

