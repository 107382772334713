import decode, { InvalidTokenError } from 'jwt-decode'
import MIAConnection from '@/../packages/mia-connection';

class Auth {

    constructor() {
        MIAConnection.initialize(process.env.VUE_APP_MIA_API, this.logout);
    }

    getToken() {
        return localStorage.token ?? sessionStorage.token;
    }

    setToken(token, persistent = false) {
        if (persistent) {
            localStorage.token = token;
        } else {
            sessionStorage.token = token;
        }
    }

    isLoggedIn() {
        const token = this.getToken();

        if (token) {
            try {
                const data = decode(token);
                MIAConnection.setToken(token);

                return data;
            } catch (e) {
                if (e instanceof InvalidTokenError) {
                    return false;
                } else {
                    throw e;
                }
            }
        }

        return false;
    }

    async login(username, password, remember = false) {
        const token = await MIAConnection.login('dsm', username, password);
        this.setToken(token?.access_token, remember);
        return token;
    }

    logout() {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        sessionStorage.removeItem('refreshToken');
    }
}

export default new Auth();