<template>
    <div class="button-container" :style="style" :class="themeClass">
        <slot></slot>
    </div>
</template>

<style lang="scss">
    @import '~@/scss/fonts';
    @import '~@/scss/variables';

    $default-button-size: 7em;

    .button-container {
        
        button {
            pointer-events: all;
            // --button-color: #{$text};
            // --button-background: transparent;
            // --button-border: #{$hippyblue};
            // --arrow-color: white;
            // --arrow-circle: #{$hippyblue};
        }
    }
</style>

<script>
export default {
    name: 'ButtonContainer',

    props: {
        justify: String,
        theme: {
            type: String,
            default: 'default',
            validator: (v) => ['default'].includes(v)
        },
    },

    computed: {
        themeClass() {
            return this.theme;
        },
        style() {
            const style = {};

            const styles = ['justify'];
            const themes = {'default': {'justify': 'right'} };

            let theme = themes[this.theme];

            styles.forEach(s => {
                let r = this[s] || (theme && theme[s]);
                if (r) style['--' + s] = r;
            });

            return style;
        }
    }
}
</script>