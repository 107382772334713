<template>
    <div id="video-player">
        <div class="video-player-wrapper" ref="video-player-wrapper" @click="onVideoMouseMove"></div>

        <transition name="fade">
            <div class="video-controls">
                <button :id="playing ? 'pause' : 'play'" @click="toggle"></button>
            </div>
        </transition>
    </div>
</template>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';
@import '~@/scss/fonts';

#video-player {
    position: relative;

    video {
        display: block;
        position: relative;
        height: 70%;
        width: 70%;
        margin: 1em auto 0 auto;
        text-align: center;

        @include media("<=medium") {
            width: 100%;
            height: 100%;
        }
    }

    .play-btn-area {
        position: absolute;
        left: 24vw;
        width: 9vw;
        height: 100%;
        max-width: 108px;
        max-height: 285px;
        min-width: 78px;
        min-height: 200px;

        @include media("<=medium") {
            left: 39vw;
        }

        .play-btn {
            display: flex;
            background: none;
            border: none;
            cursor: pointer;
            padding: 0;
            left: -3px;
            width: 100%;
            height: auto;
        }

        .play-btn-bg {
            position: absolute;
            width: 7vw;
            max-width: 100px;
            max-height: 290px;
        }

        .play-btn-icon {
            position: absolute;
            width: 5vw;
            top: 100px;
            max-width: 80px;
            max-height: 90px;
        }
    }

    .video-controls {
        position: absolute;
        display: block;
        width: 85%;
        height: 50px;
        display: block;
        bottom: 0;

        @include media("<=medium") {
            width: 100%;
        }

        #play {
            position: absolute;
            right: 1em;
            bottom: 1em;
            background: transparent;
            border: 0;
            box-sizing: border-box;
            width: 0;
            height: 34px;
            border-color: transparent transparent transparent $back;
            transition: 100ms all ease;
            cursor: pointer;

            // play state
            border-style: solid;
            border-width: 17px 0 17px 30px;
        }

        #pause {
            @extend #play;
            border-style: double;
            border-width: 0px 0 0px 30px;
        }

        #back {
            @extend #play;
            left: 0;
            min-width: 40px;
            min-height: 25px;
            max-width: 50px;
            max-height: 25px;
        }
    }
}
</style>

<script>
import video from '@/lib/persistent-video-element';
import Button from './Button.vue';

export default {
    components: {
        Button,
    },

    name: 'VideoPlayer',

    props: {
        src: String,
    },
    
    data: () => ({
        playing: false,
        enableControls: true,
        videoProgress: 0,
        hideControlsTimeout: null,
        hideControls: false,
    }),

    methods: {
        playVideo() {
            video.play();
        },
        toggle() {
            video.paused ? video.play() : video.pause();
        },
        updateTime() {
            this.videoProgress = video.currentTime / video.duration;
            // this.message('video-progress', this.videoProgress);
        },
        onVideoMouseMove() {
            clearTimeout(this.hideControlsTimeout);
            this.hideControls = false;
            this.hideControlsTimeout = setTimeout(() => {
                this.hideControls = true;
            }, 5000);
        }
    },

    mounted() {
        // Add element to the dom so it is visible
        this.$refs['video-player-wrapper'].append(video);

        video.autoplay = false;

        // Process the question data
        video.src           = this.src;
        video.controls      = false;
        video.currentTime   = 0;

        // Automatically change playing property based on video events
        video.onplay  = _ => this.playing = true;
        video.onpause = _ => this.playing = false;

        video.ontimeupdate = _ => this.updateTime();
        video.onmousemove  = _ => this.onVideoMouseMove();

        // Goto next module when the video ends
        video.onended = _ => this.next();

    },
    destroyed() {
        video.onplay = undefined;
        video.onpause = undefined;
        video.onmousemove = undefined;
        video.ontimeupdate = undefined;
    }
}
</script>