<template>
    <InstructionsTopBar v-if="step.topbar === 'landmarks'" :step="step" :progress="progress" />
	<TestTopBar v-else-if="step.topbar === 'dots'" :step="step" :theme="theme" />
</template>

<script>
import TestTopBar from './TestTopBar';
import InstructionsTopBar from './InstructionsTopBar';

export default {
	components: {
		TestTopBar,
		InstructionsTopBar,
	},
	props: {
		step: Object,
		progress: Number,
		theme: String
	},
}
</script>