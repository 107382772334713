// Create video element in code so we can reuse it
// This will help with the autoplay on mobile devices
const video = document.createElement('video');
video.autoplay = true;
video.playsInline = true;

// Create observer
const observer = new MutationObserver(function callback() {    
    try {
        if (video && !document.contains(video)) {
            // Reset video because there is no parent
            video.src = '';
            video.load();
        }
    }
    catch {
    }
});

// Start the observer
observer.observe(document, { 
    subtree: true, 
    childList: true 
});

async function authorizeAutoplay() {
    try {
        // Load and play
        await video.play();

        // Pause immediately 
        video.pause();

        // Remove the listener from the document
        document.removeEventListener('touchstart', authorizeAutoplay);
    } catch { }
}

// Add the listener to the document to authorize the video element to autoplay
document.addEventListener('touchstart', authorizeAutoplay);

export default video;