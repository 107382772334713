import MIAConnection from "../../mia-connection";

import { AssignmentParser } from './AssignmentParser';

import isNull from "./utils/isNull";

import QuestionSegment from "./models/segments/QuestionSegment";
import ContainerSegment from "./models/segments/ContainerSegment";
import QuestionContainerSegment from './models/segments/QuestionContainerSegment';

import { SegmentRuleCollection } from "./models/SegmentRule";

const REQUIRED_ACTIONS = [
    'prev',
    'next',
    'finished',

    'load-question',

    'session-create',
    'session-continue',
    'session-finish',
    'session-update-question-position'
];

export class AssignmentStateManager extends ContainerSegment {
    /** @type {AssignmentParser} */
    #parser;

    /**
     *  @type {{ preParse: Function, postParse: Function }} */
    #segmentParsers;

    /** @type {import('./models/SegmentRule').SegmentRuleCollection} */
    #segmentRules;

    /** @type {{ mode: 'learner'|'...', question: ?number, assignment, session: { id, position }, results }} */
    #service;

    /** @type {QuestionContainerSegment} */
    #questions;

    /** @type {Object.<string, Function>} */
    #actions = {
        'next': this.next.bind(this),
        'prev': this.prev.bind(this),

        'session-create': async () => {
            this.#service.mode = 'learner';
            this.#service.session = await MIAConnection.createAssignmentSession(this.#service.assignment.code);

            this.#questions.setStep(this.#service.session?.position);
            this.#questions.setStepByKey(this.#service.question);
        },

        'session-continue': async () => {
            this.#service.mode = 'learner';

            for (const answer of await MIAConnection.getAssignmentSessionAnswers(this.#service.assignment.code, this.#service.session.id)) {
                this.#questions.setAnswer(answer.questionId, answer);
            }

            this.#questions.setStep(this.#service.session?.position);
            this.#questions.setStepByKey(this.#service.question);
        },

        'session-update-question-position': async () => {
            if (this.#service.session && this.#service.mode === 'learner') {
                // TODO: updated status of session should be returned from the server
                // Manualy updating position for now
                const index = this.#questions.step;
                
                await MIAConnection.postAssignmentSessionPosition(this.#service.assignment.code, this.#service.session.id, index);

                this.#service.session.position = index;
            }
        },

        'session-finish': async () => {
            if (this.#service.session) {
                await MIAConnection.finishAssignmentSession(this.#service.assignment.code, this.#service.session.id);
            }
        }
    };

    /** @type {Object.<string, Function>} */
    #events = { }

    /**
     * @param {Object} config 
     * @param {import('./AssignmentParser').AssignmentParserConfig} config.parser
     * @param {import('./models/SegmentRule').SegmentRule[]} config.segmentRules;
     * @param {Object.<string, Function>} config.events
     * @param {Object.<string, Function>} config.actions
     */
    constructor(config) {
        super();

        this.#parser = new AssignmentParser(config.parser);

        this.#segmentParsers = config.segmentParsers ?? {};
        this.#segmentRules = new SegmentRuleCollection(config.segmentRules);

        Object.assign(this.#events, config.events);
        Object.assign(this.#actions, config.actions);

        // Check for missing actions
        const missingActions = REQUIRED_ACTIONS.filter(a => typeof this.#actions[a] !== 'function');

        if (missingActions.length) {
            throw new Error('Action(s) not defined: ' + missingActions.join(', '));
        }
    }

    /**
     * 
     * @param {string} assignmentCode 
     * @param {?number} sessionId 
     * @param {?number} question 
     */
    // TODO: add support for course session starting
    async start(assignmentCode, sessionId = undefined, question = undefined) {
        const assignmentRequest = MIAConnection.getAssignment(assignmentCode);
        const sessionRequest = sessionId != undefined
            ? MIAConnection.getAssignmentSession(assignmentCode, sessionId) 
            : MIAConnection.getAssignmentSessionLatest(assignmentCode)
        
        const [assignment, session] = await Promise.all([assignmentRequest, sessionRequest]);
        
        this.#service = { 
            session: session, 
            question: question, 
            assignment : this.#parser.parse(assignment)
         };

        if (session?.gradingStatus === 'finished' || session?.gradingStatus === 'inconclusive') {
            this.#service.results = await MIAConnection.getAssignmentSessionResults(assignment.code, session.id);
        } else {
            this.#service.results = undefined;
        }

        // Parse and add segments
        for (const segment of this.parse(this.#service.assignment, this.#service.session)) {
            if (!isNull(segment)) {
                this.addSegment(segment);
            }
        }

        await this.load();
    }

    /** @private */
    *parse(assignment, session) {
        if (typeof this.#segmentParsers.preParse === 'function') {
            const pre = this.#segmentParsers.preParse(assignment, session);

            if (typeof pre[Symbol.iterator] === 'function') {
                yield* pre;
            } else {
                yield pre;
            }
        }

        yield this.parseAssignment(assignment, session);

        if (typeof this.#segmentParsers.postParse === 'function') {
            const post = this.#segmentParsers.postParse(assignment, session);

            if (typeof post[Symbol.iterator] === 'function') {
                yield* post;
            } else {
                yield post;
            }
        }
    }

    /** @private */
    //eslint-disable-next-line no-unused-vars
    parseAssignment(assignment) {
        this.#questions = new QuestionContainerSegment();

        for (const questions of assignment.questions) {
            const types = questions.map(q => q.module);
            const match = this.#segmentRules.match(types);

            if (match) {
                const keys = questions.map(q => q.index);//.sort((a, b) => a - b);
                const data = Object.assign({}, match.data, { questions: questions.map((q) => ({ question: q, answer: undefined })) });
                
                this.#questions.addSegment(new QuestionSegment(undefined, keys, match.steps, data));
            } else {
                console.warn('[skipping] Could not match segment for type(s): ' + types.join(', '));
            }
        }

        return this.#questions;
    }

    /** @private */
    async load() {
        for (const action of this.activeSegment.load())
            await this.execute(action.type, action.args ?? {});
    }

    next() {
        if (super.next()) {
            return this.load();
        } else {
            return this.execute('finished');    
        }
    }

    prev() {
        if (super.prev()) {
            return this.load();
        } else {
            // Going back when already at the start
            // Reloeading the first segment can cause problems
            // If the assignment session has already been started will the reload of the question container start at the session position and not the first question as expected
        }
    }
    
    setAnswer(answer) {
        const answers = Array.isArray(answer) ? answer : [answer];

        for (const answer of answers) {
            this.#questions.setAnswer(answer.questionId, answer);
        }
    }
    
    async submitAnswer(answer, grade = false) {
        await MIAConnection.postAssignmentSessionAnswers(this.#service.assignment.code, this.#service.session.id, answer);        

        if (grade) {
            let grades = [], questionIds = Array.isArray(answer) ? answer.map(item => item.questionId) : [answer.questionId];

            for (const answer of await MIAConnection.getAssignmentSessionAnswers(this.#service.assignment.code, this.#service.session.id)) {
                this.#questions.setAnswer(answer.questionId, answer);
                if (questionIds.includes(answer.questionId)) {
                    grades.push(answer.grading);
                }
            }
            return grades;
        } else {
            this.setAnswer(answer);
            return null;
        }
    }
   
    /**
     * @private
     * @param {string} action 
     * @param {object} args 
     */
    async execute(action, args) {
        await Promise.resolve(this.#actions[action](args));
        await Promise.resolve(this.#events[action]?.());
    }

    async getSubmissionStatus() {
        return MIAConnection.getAssignmentSessionSubmissionStatus(this.#service.assignment.code, this.#service.session.id);        
    }

    /**
     * The current session
     * @public
     * @type {Object}
     */
    get session() { return this?.#service?.session; }

    /**
     * The current assignmnet
     * @public
     * @type {Object}
     */
    get assignment() { return this?.#service?.assignment; }
}