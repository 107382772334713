<template>
    <div class="green-bg h-align-center">
        <div id="welcome-screen" class="v-align-center">
            <svgLogo class="logo align-center" />
            <h1 class="blue-text align-center">Welcome</h1>
            <h2 class="align-center">Nice to meet you!</h2>
            <strong class="align-center">Choose your language</strong>
            <div id="lang-selector" >
                <a v-for="language in languages" :key="language.locale" class="btn circular-btn blue-text" @click="setLanguage(language)">
                    {{ language.label }}
                </a>
            </div>
        </div>
    </div>
</template>

<style lang="scss">

</style>

<script>
import store from '../store';

const LANGUAGES = Object.freeze([
    {label: 'NL', locale: 'nl'}
]);

export default {
    name: 'Landing',
    components: {
        svgLogo: require('@/assets/svg/logo.svg?inline')
    },
    computed: {
        languages: () => LANGUAGES
    },
    methods: {
        setLanguage(language) {
            this.$store.commit('locale', language.locale);
            this.$router.push({ name: 'login' });
        }
    }
}
</script>