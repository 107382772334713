import Segment from './Segment';

export default class ContainerSegment extends Segment {
    /**
     * The index of active segment
     * @private
     * @type {number}
     */
    #active = 0;

    /**
     * The index of active segment
     * @public
     * @type {number}
     */
    get active() { return this.#active; }

    /**
     * The segments
     * @protected
     * @type {Segment[]} 
     */
    segments = [];

    /**
     * The active segment
     * @protected
     * @type {Segment}
     */
    get activeSegment() {
        return this.segments[this.active];
    }

    /** @override */
    get step() {
        return this.segments.reduce((sum, segment, index) => sum + (this.active > index  ? segment.steps : 0), this.activeSegment.step);
    }

    /** @override */
    get steps() {
        return this.segments.reduce((sum, segment) => sum + segment.steps, 0);
    }
    
    get status() {
        return Object.assign(super.status, {
            active: this.active,
            segments: this.segments.map((s, i) => this.getSegmentStatus(s, i)),
        });
    }

    /** @protected **/
    getSegmentStatus(segment, index) {
        return segment.status;
    } 

    /** @override */
    setStepByKey(key, step = 0/*, ignoreAlreadyActive = true*/) {
        if (super.setStepByKey(key, step)) {
            return true;
        }

        for (let i = 0; i < this.segments.length; ++i) {
            const segment = this.segments[i];

            if (segment.setStepByKey(key, step)) {
                if (!this.setActive(i)) 
                    throw new Error('Could not update active');

                return true;
            }
        }

        return false;
    }

    /** @override */
    setStep(step) {
        for (let i = 0; i < this.segments.length && step >= 0; ++i) {
            const segment = this.segments[i];

            if (segment.setStep(step)) {
                if (!this.setActive(i)) 
                    throw new Error('Could not update active');

                return true;
            }

            step -= segment.steps;
        }

        return false;
    }

    setActive(active, updateOtherSegmentSteps = true) {
        if (active < 0 || active >= this.segments.length)
            return false;
        
        this.#active = active;

        // Update for segments on both sides of the active segment
        // Needed when not in the normal flow.
        // It can lead to issues where going backwards you will not be at the end of the previous segment but at the beginning

        if (updateOtherSegmentSteps) {
            for (let i = 0; i < this.segments.length; ++ i) {
                if (i < active) this.segments[i].setStepAtEnd();
                if (i > active) this.segments[i].setStepAtBegin();
            }
        }

        return true;
    }

    addSegment(segment) {
        this.segments.push(segment);
    }

    *load() {
        if (this.activeSegment) {
            yield* this.activeSegment.load();
        } else {
            yield { type: 'next' };
        }
    }

    /** @override */
    next() {
        if (this.activeSegment?.next()) {
            return true;
        }

        return this.setActive(this.active + 1, false);
    }

    /** @override */
    prev() {
        if (this.activeSegment?.prev()) {
            return true;
        }

        return this.setActive(this.active - 1, false);
    }
}