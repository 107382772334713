<template>
    <div class="instructions-top-bar">
        <div class="top-bar-wrapper">
            <div v-if="step" :class="classes">
                <div class="progress-bar" v-if="step">
                    <div class="step-icons">
                        <template v-for="item in steps" >
                            <component :is="item.svg" :key="'icon-' + item.nr"></component>
                        </template>
                    </div>
                    <div class="step-bar">
                        <template v-for="item in steps" >
                            <instruction-point :key="'instruction-point-' + item.nr" :step="item.nr" :activeStep="activeStep" :text="item.text" />
                            <div v-if="item.nr != steps.length - 1" :key="'step-line-' + item.nr" class="step-line">
                                <div class="step-line-progress" :style="{'--percentage': stepProgress(item)}"></div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import '~@/scss/variables';
@import '~@/scss/mixins';
@import '~@/scss/fonts';

.instructions-top-bar .top-bar {
    width: 100vw;
    min-width: 315px;
    display: flex;
    position: absolute;
    z-index: 1;

    .progress-bar {
        margin: 2vw auto 0;

        .step-icons {
            svg {
                width: 50px;
                margin: 0 2vw;
                padding: 0;
            }
            .active {
                filter: brightness(250%);
            }
        }

        .step-bar {
            margin: 0 2vw;

            * {
                display: inline-block;
            }
            .step-line {
                position: relative;
                background-color: $dark-gray-color;
                width: 4vw;
                height: 2px;
                margin: 0 -12px 12px -12px;
                padding: 0 12px 0 12px;
            }

            .step-line.active {
                background-color: white;
            }

            .step-line-progress {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                background-color: white;
                transition: width 0.1s ease;
                width: var(--percentage);
            }
        }
    }
}
</style>

<script>
import InstructionPoint from './InstructionPoint';

const STEPS = Object.freeze([
    {nr: 0, text: 'Start', svg: require('@/assets/svg/start.svg?inline')},
    {nr: 1, text: 'Main entrance security', svg: require('@/assets/svg/landmark1.svg?inline')},
    {nr: 2, text: 'Landmark 2', svg: require('@/assets/svg/landmark2.svg?inline')},
    {nr: 3, text: 'Landmark 3', svg: require('@/assets/svg/landmark3.svg?inline')},
    {nr: 4, text: 'Eind toets', svg: require('@/assets/svg/end.svg?inline')}
]);

export default {
    name: 'InstructionsTopBar',

    components: {
        InstructionPoint
    },

    props: {
        step: Object,
        progress: {type: Number, default: 1}
    },

    computed: {
        steps: () => STEPS,
        activeStep() {
            return Math.floor((this.step.current + 1) / 2);
        },
        classes() {
            return { 
                'top-bar': true, 
                'transparent': this.step === undefined 
            };
        }
    },

    methods: {
        stepProgress(item) {
            return (this.activeStep > item.nr ? 100 : this.activeStep  == item.nr ? this.progress * 100 : 0) + '%';
        }
    }
}
</script>