import { Segment } from '@/../packages/mia-assignment-manager';

export default class AssignmentSegment extends Segment {
    /**
     * Unique identifier for this question segment
     * @type {string}
     */
    #uuid = Math.random().toString(16).slice(2);

    constructor (args) {
        super(undefined, [], 0);

        this.args = args;
    }

    *load() {
        yield { 
            type : 'load-component',
            args: Object.assign({}, this.args, { 
                uuid: this.#uuid,
                steps: { 
                    current: this.step, 
                    max: this.steps
                }
            })
        };
    }
}