import ContainerSegment from './ContainerSegment';
import QuestionSegment from './QuestionSegment'
import isNotNull from '../../utils/isNotNull';

export default class QuestionContainerSegment extends ContainerSegment {
    /** @type {Array} */
    get questionsData() {
        return this.segments.flatMap(s => s.args.questions);
    }

    *load() {
        if (this.activeSegment instanceof QuestionSegment) {
            yield { type: 'session-update-question-position', args: { index: this.active } };
        }

        yield* super.load();
    }

    setAnswer(id, answer) {
        const data = this.questionsData.find(d => d?.question.id === id);

        if (data) {
            data.answer  = answer;
            data.grading = answer?.grading;
        }
    }

    clearAnswers() {
        for (const data of this.questionsData.filter(isNotNull)) {
            data.answer = undefined;
        }
    }

    /** @protected **/
    getSegmentStatus(segment, index) {
        const status = super.getSegmentStatus(segment, index);
        
        status.index = segment.keys[0];

        return status;
    }

}