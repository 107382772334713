import Segment from './Segment';

export default class QuestionSegment extends Segment {
    /**
     * Unique identifier for this question segment
     * @type {string}
     */
    #uuid = Math.random().toString(16).slice(2);

    constructor (label, keys, steps, args) {
        super(label, keys, steps);

        this.args = args;
    }
    
    get status() {
        return Object.assign(super.status, {
            answered: this.args.questions.some(({ question: q }) => q.grading !== 'none') && 
                      this.args.questions.every(({ question: q, answer: a }) => q.grading === 'none' || (a?.answer || a?.answerParts?.length))
        });
    }

    *load() {
        yield { 
            type : 'load-question', 
            args: Object.assign({}, this.args, { 
                uuid: this.#uuid,
                steps: { 
                    current: this.step, 
                    max: this.steps
                }
            })
        };
    }
}