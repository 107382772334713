<template>
    <div class="gray">
        <div class="bg v-align-center full-width">
            <div class="container">
                <svgLogo class="logo-small" />

                <div id="result-screen" class="v-align-center">
                    <h1 class="blue-text align-center">Inloggen</h1>

                    <validation-observer ref="formObserver">
                        <form method="POST" class="login-form" @submit.prevent="submit">
                            <div v-if="requestFailed" style="color: red; padding-left: 2%;">{{requestFailedMsg}}</div>

                            <validated-input name="email" type="text" :showlabel="false" placeholder="Vul je e-mail adres in" v-model="form.email" rules="email-required|email">e-mailadres</validated-input>
                            <validated-input name="password" type="password" :showlabel="false" placeholder="Vul je wachtwoord in" v-model="form.password" rules="pass-required">wachtwoord</validated-input>

                            <!-- <div class="login-extras">
                                <label>onthoud mij <input type="checkbox" v-model="form.remember"/></label>
                            </div> -->
                            <br/>
                            <button :style="{visibility: loading && !going ? 'hidden' : 'visible'}" class="btn round blue align-center" type="submit">Log in</button>
                            <div v-if="loading && !going" class="loader"></div>
                        </form>
                    </validation-observer>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
  input {
    box-shadow: 1vw 1vh 1vw 0px rgb(0 0 0 / 0.1);
  }
</style>

<script>
import Auth from '../services/Auth'
import AssessmentFlowService from '../services/AssessmentFlowService';

export default {
    name: 'Login',
    props: {
        company: { type: Boolean }
    },
    components: {
        svgLogo: require("@/assets/svg/logo.svg?inline"),
    },
    data: () => ({
        requestFailed: false,
        requestFailedMsg: '',
        loading: false,
        going: false,

        form: {
            email: "",
            password: "",
            remember: false,
        }
    }),
    methods: {
        async submit() {
            this.requestFailed = false;
            this.loading = true;

            if ((await this.$refs['formObserver'].validate())){
                try {
                    await Auth.login(this.form.email, this.form.password, this.form.remember);

                    this.going = true;
                    this.$nextTick(function () {
                        //this.$router.push({ name: 'home' });
                        AssessmentFlowService.next();
                    });
                } catch (e) {
                    console.log(e);
                    switch(e.response.status) {
                        case 400:
                            this.requestFailedMsg = 'E-mail en/of wachtwoord is incorrect.';
                            break;
                        default:
                            this.requestFailedMsg = 'Er is iets fout gegaan, probeer het later nog eens.';
                            break;
                    }
                    this.requestFailed = true;
                }
            }

            this.loading = false;
        }
    },

    beforeRouteEnter (to, from, next) {
        const role = Auth.isLoggedIn();

        if (!role || 'force' in to.query) {
            next();
        } else {
            AssessmentFlowService.next();
            //next({ name: 'home' });
        }
    }
}
</script>