import './imports/vee-validator'

import Vue from 'vue'
import VueRouter from 'vue-router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import TopBar from './components/topbar/TopBar.vue';
import ButtonContainer from './components/ButtonContainer.vue';
import Button from './components/Button.vue';
import ValidatedInput from './components/ValidatedInput.vue';

// App
import router from './routes.js'

import App from './App.vue'
const AppConstructor = Vue.extend(App)

import store from './store.js';

// Styling
import './scss/main.scss'

Vue.config.productionTip = false

Vue.use(VueRouter);

Vue.component('validation-observer', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);
Vue.component('validated-input', ValidatedInput);
Vue.component('button-container', ButtonContainer);
Vue.component('btn', Button);
Vue.component('top-bar', TopBar);

window.$vm = new AppConstructor({
  router,
  store,
  mounted: function() {
  },
  created: function() {    
  },
}).$mount('#app')