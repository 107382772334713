<template>
    <div :class="theme">
        <div class="bg v-align-center full-width">
            <div class="container">
                <svgLogo class="logo-small" />

                <h1 class="title align-center">Einde toets</h1>
                <p class="align-center">Verstuur de toets om het resultaat te zien</p>

                <button condensed class="align-center btn round" @click="next">Verstuur</button>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import "~@/scss/fonts";

.EndModule {
    width: 100%;
    display: flex;
    flex-direction: row;

    h1 {
        @include font-big(0.9);
        line-height: 1em;
        margin-bottom: 0.3em;
    }

    .btn {
        margin-top: 2em;
    }
}
</style>

<script>

import base from './base';

export default {
    name: "EndModule",
    extends: base(),
    components: {
        svgLogo: require("@/assets/svg/logo.svg?inline"),
    },
    props: {
        title: String,
        description: String,
        theme: {type: String, default: 'blue'}
    }
};
</script>