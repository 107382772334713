import axios from "axios";

/**
 * Template tag function to url encode all keys
 * @param {string[]} strings 
 * @param  {...string} keys 
 * @returns string
 */
function URIencode(strings, ...keys) {
    return keys.reduce((s, k, i) => s + encodeURIComponent(k) + strings[i + 1], strings[0]);
}

function validateStatus(status) {
    return (status >= 200 && status < 300) || status == 404;
}

function asFormData(obj) { //eslint-disable-line
    return Object.entries(obj).reduce((d, [k, v]) => { d.set(k, v); return d; }, new URLSearchParams());
}

class MIAConnection {
    /** @type {boolean} */
    #initialized = false;

    /** @type {string} */
    #baseUrl;

    /** @type {string} */
    #token;

    /** @type {string} */
    #refreshToken;

    /** @type {Function} */
    #logout;

    /** @type {Object} */
    #router;


    /**
     * 
     * @param {String} url
     * @param {Function} logout Callback when the token is invalid/expired 
     */
    initialize(url, logout) {
        if (this.#initialized) throw new Error('Connection already initialized');

        this.#baseUrl = url;
        this.#logout = logout;

        this.#initialized = true;
    }

    setRouter(router) {
        this.#router = router;
    }

    setToken(token, refreshToken = undefined) {
        this.#token = token;
        this.#refreshToken = refreshToken;
    }

    /**
     * 
     * @param {'GET'|'POST'} method The HTTP method of the request
     * @param {String} url The relative api path 
     * @param {Object} data The data object
     * @param {Boolean} auth To authenticate the request or not
     * @param {String} responseType 
     * @param {String} contentType 
     * @param {Boolean} returnRawResponse 
     */
    async request(method, url, data = undefined, auth = true, responseType = 'json', contentType = 'application/json', returnRawResponse = false) {
        if (!this.#initialized) throw new Error('Connection not initialized');

        let headers = {
            'Content-Type': contentType
        };

        if (auth) {
            headers['Authorization'] = 'Bearer ' + this.#token;
        }

        // Convert the data if it is not a GET request
        if (data != undefined && headers['Content-Type'] === 'application/json' && method !== 'GET') {
            data = JSON.stringify(data);
        }

        // Add the base url
        url = this.#baseUrl + url;

        return await axios({ method, url, headers, data, responseType, validateStatus })
            .then(r => returnRawResponse ? r : r.data)
            .catch(
                function (error) {
                    if (error?.response?.status === 401) {
                        this.#token = undefined;
                        this.#refreshToken = undefined;
                        this.#logout();

                        this.#router.push({ name: 'login' });
                        return;
                    }

                    // Throw it down the chain
                    throw (error);

                }.bind(this)
            );
    }

    refreshToken(refreshToken) { //eslint-disable-line
        // Clear the stored refresh token so it won't get in a infinite loop
        this.#refreshToken = null;

        // Implement refresh token
        throw new Error('Not implemented');
    }

    // Account

    async login(domain, username, password) {
        const data = await this.request('POST', URIencode`token`, 
        asFormData({
            'grant_type': 'password',
            'username': username,
            'password': password,
            'domain': domain}), false, 'json', 'application/x-www-form-urlencoded');

        this.setToken(data.access_token);
        return data;
    }

    async registerGuestAccount(domain, loginAsGuest = true) {
        const token = await this.request('POST', URIencode`account/register/${domain}/guest`);

        if (loginAsGuest) {
            this.setToken(token);
        }

        return token;
    }

    // Assignment

    getAssignment(code) {
        return this.request('GET', URIencode`assignments/${code}`);
    }

    getAssignmentSession(code, sid) {
        return this.request('GET', URIencode`assignments/${code}/session/${sid}`);
    }

    getAssignmentSessionLatest(code) {
        return this.request('GET', URIencode`assignments/${code}/session/latest`);
    }

    getAssignmentSessionResults(code, sid) {
        return this.request('GET', URIencode`assignments/${code}/session/${sid}/results`);
    }

    getAssignmentSessionAnswers(code, sid) {
        return this.request('GET', URIencode`assignments/${code}/session/${sid}/answers`);
    }

    getAssignmentSessionSubmissionStatus(code, sid) {
        return this.request('GET', URIencode`assignments/${code}/session/${sid}/submission/status`);
    }

    createAssignmentSession(code) {
        return this.request('POST', URIencode`assignments/${code}/session`);
    }

    postAssignmentSessionPosition(code, sid, index) {
        return this.request('POST', URIencode`assignments/${code}/session/${sid}/position`, index);
    }

    postAssignmentSessionAnswers(code, sid, answers) {
        if (sid == null) {
            return this.request('POST', URIencode`assignments/${code}/session/answers`, Array.isArray(answers) ? answers : [answers]);
        } else {
            return this.request('POST', URIencode`assignments/${code}/session/${sid}/answers`, Array.isArray(answers) ? answers : [answers]);
        }
    }

    finishAssignmentSession(code, sid) {
        return this.request('POST', URIencode`assignments/${code}/session/${sid}/finish`);
    }

    // Course Session

    postCourseSessionEvent(code, category, type, parameter) {
        return this.request('POST', URIencode`course/${code}/event`, { category, type, parameter });
    }

    postCourseSessionKeepAlive(code) {
        return this.request('POST', URIencode`course/${code}/event/keep-alive`);
    }
}

export default new MIAConnection();