import { ValidationObserver , ValidationProvider, extend } from 'vee-validate';
import { required, email, min, max } from 'vee-validate/dist/rules.umd.js';
import { setInteractionMode } from 'vee-validate';
import { localize } from 'vee-validate';
import nl from 'vee-validate/dist/locale/nl.json';

setInteractionMode('eager');

localize('nl', nl);
localize({
    nl: {
        names: {
            // todo
        }
    }
})

extend('required', required);
extend('confirm', {
    validate: (value, origin) => {
        if(value != origin.origin)
        {
            return false;
        }
        return true;
    },
    message: "De ingevulde wachtwoorden komen niet overeen",
    params: ["origin"]
})

extend('min', min);
extend('max', max);
extend('email-required', Object.assign(required, { message: 'E-mail is verplicht.' }));
extend('pass-required', Object.assign(required, { message: 'Wachtwoord is verplicht.' }));
extend('checkbox-required', value => {
  if(value)
    return true;

  return 'Dit veld is verplicht';
})
extend('email', Object.assign(email, { message: 'Voer een geldig e-mail adres in' }));
extend('pass-strong', value => {
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})");
    if (strongRegex.test(value)) {
        return true;
    }
    return 'Voor je 6 karakters nodig, een hoofdletter, kleine letter en een speciaal teken?';
})