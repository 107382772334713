<template>
    <div class="gray">
        <div class="bg v-align-center full-width">
            <div class="container">
                <svgLogo class="logo-small" />
                <div id="result-screen" class="v-align-center">
                    <h1 class="blue-text align-center">{{headerText}}</h1>

                    <div class="progress-bar-wrapper">
                        <div class="progress-bar" :class="(!success) ? 'failed' : 'success'" :style="{ '--percentage': percentage + '%'}" v-if="percentage !== undefined">
                            <div class="progress-bar-slider" :class="!success ? 'failed' : 'success'" >{{Math.round(percentage)}}%</div>
                        </div>
                    </div>

                    <p class="align-center" v-if="!success && !retake">Helaas je hebt het niet gehaald. Druk op herkansing om het nogmaals te proberen.</p>
                    <p class="align-center" v-else-if="!success && retake">Helaas je hebt het niet gehaald en er zijn geen herkansingen meer. Neem contact op met met de balie voor meer informatie.</p>
                    <p class="align-center" v-else-if="success">Gefeliciteerd je hebt het gehaald en mag het terrein betreden.</p>

                    <button v-if="success || retake" condensed class="align-center btn round" @click="resetRetake">Einde</button>
                    <button v-else condensed class="align-center btn round" @click="startRetake">Herkansen</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import "~@/scss/fonts";
@import '~@/scss/variables';
@import '~@/scss/mixins';

#result-screen {
    width: 100%;
    display: flex;
    flex-direction: columns;

    h1 {
        @include font-big(0.9);
        line-height: 1em;
        margin-bottom: 0.3em;
    }

    .btn {
        margin-top: 2em;
    }

    .progress-bar-wrapper {
        flex: 1 1 auto;
        width: 90%;
        margin-block: 1em;

        padding: 0 5%;

        .progress-bar {
            width: 100%;
            max-width: 420px;
            margin: 0 auto;
            padding: 2px;

            position: relative;

            overflow: hidden;
            border-radius: 0.5em;

            .progress-bar-slider {
                transition: width 1s ease;
                width: var(--percentage);
                box-sizing: border-box;
                min-width: 0.1px;
                text-align: end;
                margin: 3px 4px;
                padding: 2px 10px;
                color: white;
                border-radius: 0.5em;

                &.success {
                    background-color: $green-color;
                }

                &.failed {
                 background-color: $error-color;   
                }
            }

            &::after {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: inherit;
            }

            &.success::after  {
                border: $green-color solid 2px;
            }

            &.failed::after  {
                border: $error-color solid 2px;
            }
        }
    }
}
</style>

<script>
import AssessmentFlowService from '../services/AssessmentFlowService';

import MIAConnection from '@/../packages/mia-connection';

export default {
    name: 'AssessmentResult',
    props: {
        code: String
    },
    data : () => ({
        loading: true,
        percentage: 0,
    }),
    methods: {
        startRetake() {
            if (!this.success && !this.retake) {
                this.$store.commit('retake', true);
            }

            AssessmentFlowService.next();
        },
        // TEMP for testing
        resetRetake() {
            this.$store.commit('retake', false);
        }
    },
    components: {
        svgLogo: require("@/assets/svg/logo.svg?inline"),
    },
    computed: {
        headerText() {
            return this.success ? "Geslaagd" : "Helaas";
        },
        success() {
            return this.percentage >= 70;
        },
        retake() {
            return this.$store.getters.retake;
        }
    },
    async mounted() {
        let session = await MIAConnection.getAssignmentSessionLatest(this.code);
        let results = await MIAConnection.getAssignmentSessionResults(this.code, session.id);
        if (results.length) {
            this.percentage = results[0].result;
        }
        this.loading = false;
    }
}
</script>