<template>
    <div class="light-gray-bg v-align-center">
        <div class="container" :id="'q' + question.id">
            <svgLogo class="logo-small" />

            <h1 class="question title blue-text align-center" v-html="question.title"></h1>
            <div class="question text blue-text" v-html="question.text"></div>
            <button condensed class="align-center btn round green" @click.prevent="next">{{ question.skipText == null ? 'Volgende' : question.skipText }}</button>
        </div>
    </div>
</template>

<style lang="scss">
@import '~@/scss/variables';
@import '~@/scss/fonts';

.TextModule {
    width: 100%;
    display: flex;
    flex-direction: column;

    // Use the id of question to select the correct width
    #q858 {
        &.container {
            max-width: 1200px;
            padding: 1em 2em;
        }
    }

    p {
        @include font-tiny(0.8);
        line-height: 140%;
    }

    .question.title {
        margin: 0 5vw 0.5em;
        line-height: 1em;
    }

    .question.text {
        display: flex;
        flex-direction: column;
        margin: 0 5vw;
    }

    .btn {
        margin-top: 1em;

        &.download {
            margin-right: auto;
            @include font-tiny(.6);
            background: $green-color url(~@/assets/svg/download.svg) 10px center no-repeat;
            background-size: 20px;
            padding-left: 40px;
            color: $blue-color;

            &:hover {
                color: $blue-color;
            }
        }
    }

    #columns {
        display: grid;
        grid-template-columns: repeat(3, 33%);
        grid-template-rows: auto;
        gap: 1.5em;

        @include media("<=medium") {
            grid-template-columns: 100%;
            grid-template-rows: auto;
        }
    }

    #emergency-number {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto;
        margin-top: 1em;
        margin-bottom: 0.5em;
        align-items: start;

        p {
            margin-top: 0;
            @include font-tiny(0.6);
        }
    }

    #age-icon {
        width: 5vw;
        height: 4vw;
        max-width: 90px;
        max-height: 79px;
        min-width: 45px;
        min-height: 40px;
        background: url('~@/assets/svg/age.svg') top left no-repeat;
        background-size: contain;
    }

    #camera-icon {
        width: 5vw;
        height: 4vw;
        max-width: 90px;
        max-height: 79px;
        min-width: 45px;
        min-height: 40px;
        background: url('~@/assets/svg/camera.svg') top left no-repeat;
        background-size: contain;
    }

    #data-icon {
        display: block;
        width: 5vw;
        height: 4vw;
        max-width: 90px;
        max-height: 79px;
        min-width: 45px;
        min-height: 40px;
        background: url('~@/assets/svg/data.svg') top left no-repeat;
        background-size: contain;
    }

    #alarm-icon {
        display: block;
        width: 5vw;
        height: 4vw;
        max-width: 90px;
        max-height: 79px;
        min-width: 60px;
        min-height: 40px;
        background: url('~@/assets/svg/alarm.svg') top left no-repeat;
        background-size: contain;
    }

    #phone-icon {
        display: block;
        width: 5vw;
        height: 4vw;
        max-width: 70px;
        max-height: 79px;
        min-width: 35px;
        min-height: 40px;
        background: url('~@/assets/svg/phone.svg') top left no-repeat;
        background-size: contain;
    }
}
</style>

<script>
import base from './base';

export default {
    name: 'TextModule',
    components: {
        svgLogo: require('@/assets/svg/logo.svg?inline'),
    },
    extends: base()
}
</script>