<template>
  <div class="main">
    <top-bar v-if="step && component !== 'MovieplayerModule'" :step="step" :progress="videoProgress" :theme="topbarTheme"></top-bar>
    <component v-if="component" :key="key" :is="component" :questions="questions" v-bind="props" :message="message" :class="['module', component]"></component>

    <div class="debug" v-if="debug">
      <button @click.prevent="message('prev')">&#x3C;&#x3C;</button>
      <button @click.prevent="message('next')">&#x3E;&#x3E;</button>
    </div>
  </div>
</template>

<script>

const courseCode = 'dsm';

// MIA packages
import MIACourseSessionManager from '@/../packages/mia-course-session';
import { AssignmentStateManager } from '@/../packages/mia-assignment-manager';
import AssignmentStateConfig from '../AssignmentStateManagerConfig';

// Module components
import components from './modules';

import AssessmentFlowService from '../services/AssessmentFlowService';

export default {
  name: 'Assessment',

  props: {
    code: String
  },

  components: Object.assign({}, components, {
    // additional components
  }),

	data : () => ({
    debug: false,
    key: undefined,
    step: undefined,
    component: undefined,
    questions: undefined,
    props: {},
    actions: {},
    videoProgress: 0,
    topbarTheme: undefined,
  }),

  methods: {
    async message(type, ...args) {
      if (this.actions[type] instanceof Function) {
        return await this.actions[type].apply(this, args);
      }
    },
    updateStatus() {
      const status = this.manager.status;
      const topbar = this.manager.assignment?.meta?.topbar;

      this.videoProgress = 0; // reset
      this.step = Object.assign({
        // Assign topbar but remove topbar at test EndModule 
        topbar: (this.component == 'EndModule' && topbar == 'dots') ? 'none' : topbar
        }, status.steps);
    },
    exit() {
      MIACourseSessionManager.stop(courseCode); 
      this.$router.push({ name: 'home' });
    },
    async awaitSubmission() {
      var result = await this.manager.getSubmissionStatus();

      console.log('awaiting submission status:', result);
      if (result == "pending") {
        setTimeout(this.awaitSubmission, 1500);
      } else {
        this.next();
      }
    },
    next() {
      // Go to next assignment
      AssessmentFlowService.next();
    }
  },

	async mounted() {
    this.debug = true;//sessionStorage.debug || process.env.VUE_APP_BUILD_TARGET == 'development';

		const config = Object.assign({}, AssignmentStateConfig(), {
			actions: {
        'load-component': (data) => {
          console.log('load-component', data?.component);

          if (data?.component === 'StartModule' || data?.component === 'EndModule') {
            const topbar = this.manager.assignment?.meta?.topbar;
            this.topbarTheme = this.manager.assignment?.meta?.theme;
            if (topbar == 'steps') {
              console.log('override steps for', topbar)
              this.steps = { current:0, max:1};
              this.updateStatus();
            }
          }
          this.key       = data.uuid ?? Math.random().toString(16).slice(2);
          this.component = data.component;
          this.props     = data.params;
        },
				'load-question': (data) => {
          this.topbarTheme = undefined;
					this.key       = data.uuid ?? Math.random().toString(16).slice(2);
					this.component = data.component;
          this.questions = data.questions;
          this.steps     = data.steps ?? { current: 0, max: 1}
          this.props     = {};
				},
				'finished': () => {
          MIACourseSessionManager.stop(courseCode); 
          this.awaitSubmission();
        }
      },
      events: {
        'prev': this.updateStatus,
        'next': this.updateStatus
      }
    });

    // Module actions
    this.actions = {
      async prev() {
        console.log('prev');
        await this.manager.execute('prev');
      },
      async next() {
        await this.manager.execute('next');
      },
      async 'save-answer'(answer, result = false) {
        return await this.manager.submitAnswer(answer, result);
      },
      'route'(route) {
        vue.$router.push(data.route);
      },
      'video-progress'(data) {
        this.videoProgress = data || 0;
      }
    };

    await MIACourseSessionManager.start(courseCode, 30000);

    this.$watch('code', async (code) => {
      this.manager = new AssignmentStateManager(config);
      await this.manager.start(this.code);
    }, { immediate: true });
	}
}
</script>

<style lang="scss">
@import '~@/scss/variables';
@import '~@/scss/mixins';

.debug {
   position: absolute; 
   bottom: 0;
   left:0;
   right:0;
   display: flex;

   > button {
     position: absolute;
     z-index: 2000;
     opacity: 0.5;
     padding:0;
     width: 40px;
     height: 40px;
     margin: -45px 2px 0 2px;

     &:last-child {
      right: 0;
     }
   }
}

.main {
  display: flex;
  flex-grow: 1;
}

</style>