<template>
    <div class="step-point" :class="{active:activeStep>=step, clickable:activeStep==step}" @click="showTextBalloon = !showTextBalloon">
        <transition name="slideup">
        <div v-if="activeStep==step && showTextBalloon" class="text-balloon">
            <svgTextBalloon class="bg" />
            <div class="text-area">
                <span class="blue-text">{{ text }}</span>
            </div>
        </div>
        </transition>
    </div>
</template>

<style lang="scss">
@import '~@/scss/variables';

.instructions-top-bar .top-bar {
    .progress-bar {
        .step-bar {
            .step-point {
                margin: 0 12px;
                width: 20px;
                height: 20px;
                border: 3px solid $dark-gray-color;
                border-radius: 20px;

                &.active {
                    background-color: white;
                    border-color: white;
                    &.clickable {
                        cursor: pointer;
                    }
                }

                .text-balloon {
                    width: 50px;
                    height: 33px;
                    position: absolute;
                    margin: 30px 0 0 -35px;

                    >* {
                        position: absolute;
                    }

                    .text-area {
                        line-height: 25px;
                        text-align: center;
                        font-size: 0.4em;
                        color: $dark-gray-color;
                        width: 90px;
                        top: 5px;
                        height: 25px;

                        span {
                            display: inline-block;
                            vertical-align: middle;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}
</style>

<script>
export default {
    name: 'InstructionPoint',

    components: {
        svgTextBalloon: require('@/assets/svg/textballoon.svg?inline'),
    },

    props: {
        step: Number,
        activeStep: Number,
        text: String
    },

    data: () => ({
        showTextBalloon: false
    }),
}
</script>