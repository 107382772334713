/**
 * @typedef {Object} AssignmentParserConfig
 * @property {Object.<string, ModuleParser>} moduleParsers The parsers used to convert the backend modules to application modules
 * 
 * @callback ModuleParser
 * @param   {Object} module The module retrived from the backend
 * @param   {Object} assignment The assignment object from the backend
 * @returns {Object} The object used in the application
 */

import isNotNull from "./utils/isNotNull";

export class AssignmentParser {
    /** @type {AssignmentParserConfig} */
    #config;

    /**
     * @param {AssignmentParserConfig} config 
     */
    constructor(config) {
        this.#config = Object.freeze(config);
    }

    parse(assignment) {
        const data = {
            code: assignment.code,
            title: assignment.title,
            description: assignment.description,
            submit: !assignment.noSubmit,
            maxNumberOfCompletions: assignment.maxNumberOfCompletions,

            meta: assignment.assignmentMetas || {},
            questions: []
        };

        let collection;
        for (const { question: q, index, collection: c } of assignment.assignmentQuestions) {
            try {
                const question = Object.assign(this.parseModule(q, assignment), { index });

                if (isNotNull(c)) {
                    if (c !== collection) {
                        collection = c;
                        data.questions.push([]);
                    }

                    data.questions[data.questions.length - 1].push(question);
                } else {
                    data.questions.push([question]);
                }
            } catch (e) {
                console.warn('[skipping]', e);
            }
        }

        // Sort all question within the segments
        data.questions.forEach(q => q.sort((a,b) => a.index - b.index));

        // Sort all segments based on the first index
        data.questions.sort((a,b) => a[0].index - b[0].index);

        return data;
    }

    /** @private */
    parseModule(module, assignment) {
        const parser = this.#config.moduleParsers[module.moduleName];

        if (typeof parser !== 'function') {
            throw new Error('Could not parse type ' + module.moduleName);
        }

        let data = {
            id     : module.id,
            code   : module.code,
            module : module.questionType.module,
            grading: module.questionType.gradingMethod,
            title  : module.title,
            hint   : module.hint,
            explanation: module.explanation
        }

        return Object.assign(data, parser(module, assignment));
    }
}