<template>
    <div>
        <div class="module-fullscreen-wrapper">
            <transition name="fade">
                <div v-if="!playing && backgroundImage" class="video-poster" :style="{backgroundImage:'url(' + backgroundImage + ')'}"></div>
            </transition>
            <div class="video-wrapper" ref="video-wrapper" @click="onVideoMouseMove"></div>

            <svgLogo class="logo-small" />

            <transition name="slide-overlay">
                <div v-if="!playing" class="video-overlay green-bg v-align-center" @click="playVideo">
                    <div class="video-description">
                        <h2 class="blue-text">Bekijk de video</h2>
                        <div class="blue-text" v-if="description" v-html="description"></div>
                    </div>
                    <div class="play-btn-area">
                        <button class="play-btn">
                            <svgPlayBtnBg class="play-btn-bg" />
                            <svgPlayBtn class="play-btn-icon" />
                        </button>
                    </div>
                </div>
            </transition>

            <transition name="fade">
                <div v-if="enableControls && !hideControls" class="video-controls">
                    <button id="back" @click="seek"><svgTenSecBackBtn class="back-btn-icon" /></button>
                    <button :id="playing ? 'pause' : 'play'" @click="toggle"></button>
                    <progress id="progress" min="0" :value="videoProgress"></progress>
                </div>
            </transition>
        </div>
    </div>
</template>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';
@import '~@/scss/fonts';

.MovieplayerModule {

    .module-fullscreen-wrapper {

        .video-poster {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
            pointer-events: none;
        }

        .video-wrapper {
            video {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                background-color: black;
            }
        }

        .video-overlay {
            z-index: 2;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            width: 25vw;
            border-radius: 0 10vh 10vh 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            @include media("<=medium") {
                width: 40vw;
            }

            .video-description {
                flex: 0;
                align-self: center;
                flex-basis: 65%;
                text-align: left;
                margin: 1em;

                h1 {
                    line-height: 110%;                    
                    font-weight: bold;
                }

                h2 {
                    line-height: 110%;                    
                    font-weight: bold;
                }
            }

            .play-btn-area {
                position: absolute;
                left: 24vw;
                width: 9vw;
                height: 100vh;
                max-width: 108px;
                max-height: 285px;
                min-width: 78px;
                min-height: 200px;

                @include media("<=medium") {
                    left: 39vw;
                }

                .play-btn {
                    display: flex;
                    background: none;
                    border: none;
                    cursor: pointer;
                    padding: 0;
                    left: -3px;
                    width: 100%;
                    height: auto;
                }

                .play-btn-bg {
                    position: absolute;
                    width: 7vw;
                    max-width: 100px;
                    max-height: 290px;
                }

                .play-btn-icon {
                    position: absolute;
                    width: 5vw;
                    top: 100px;
                    max-width: 80px;
                    max-height: 90px;
                }
            }
        }

        .video-controls {
            position: absolute;
            margin: 0 25vw;
            bottom: 1em;
            width: 50vw;
            text-align: center;

            @include media("<=medium") {
                margin: 0 30vw;
                width: 40vw;
            }

            #progress {
                position: absolute;
                left: 0;
                bottom: 3.2em;
                height: 7px;
                width: 100%;
                appearance: none;

                &::-webkit-progress-bar {
                    background-color: $back;
                }

                &::-webkit-progress-value {
                    background-color: $green-color;
                }
            }

            #play {
                position: absolute;
                left: max(65px, 3vw);
                bottom: 1em;
                background: transparent;
                border: 0;
                box-sizing: border-box;
                width: 0;
                height: 34px;
                border-color: transparent transparent transparent $back;
                transition: 100ms all ease;
                cursor: pointer;

                // play state
                border-style: solid;
                border-width: 17px 0 17px 30px;
            }

            #pause {
                @extend #play;
                border-style: double;
                border-width: 0px 0 0 30px;
            }

            #back {
                position: absolute;
                left: 0;
                bottom: 1em;
                background: transparent;
                border: 0;
                min-width: 40px;
                height: 34px;
                max-width: 50px;
                max-height: 34px;
                cursor: pointer;
            }
        }

        .fade-enter-active, .fade-leave-active {
            transition: opacity 0.3s;
        }
        .fade-enter, .fade-leave-to {
            opacity: 0;
        }

        .slide-overlay-enter-active, .slide-overlay-leave-active {
            transition: transform 0.5s;
        }
        .slide-overlay-enter, .slide-overlay-leave-to {
            transform: translate(-200vw, 0);
        }
    }
}
</style>

<script>
import base from './base';
import video from '@/lib/persistent-video-element';
import Button from '../Button.vue';

export default {
    components: {
        Button,
        svgLogo: require('@/assets/svg/logo.svg?inline'),
        svgPlayBtn: require('@/assets/svg/playbtn.svg?inline'),
        svgTenSecBackBtn: require('@/assets/svg/10sback.svg?inline'),
        svgPlayBtnBg: require('@/assets/svg/playbtnbg.svg?inline'),
    },
    name: 'MovieplayerModule',
    
    extends: base(),

    props: {
        step: Number
    },

    data: () => ({
        playing: false,
        enableControls: false,
        description: null,
        videoProgress: 0,
        hideControlsTimeout: null,
        hideControls: false,
        backgroundImage: null,
    }),

    methods: {
        playVideo() {
            video.play();
        },
        toggle() {
            video.paused ? video.play() : video.pause();
        },
        seek() {
            video.currentTime = Math.max(video.currentTime - 10, 0);
        },
        updateTime() {
            this.videoProgress = video.currentTime / video.duration;
            this.message('video-progress', this.videoProgress);
        },
        onVideoMouseMove() {
            clearTimeout(this.hideControlsTimeout);
            this.hideControls = false;
            this.hideControlsTimeout = setTimeout(() => {
                this.hideControls = true;
            }, 55000);
        }
    },

    mounted() {
        // Add element to the dom so it is visible
        this.$refs['video-wrapper'].append(video);

        video.autoplay = false;

        // Process the question data
        video.src           = this.question.video;
        video.controls      = false;
        video.currentTime   = 0;
        this.enableControls = this.question.hideControls;

        this.description = this.question.description;
        this.backgroundImage = this.question.backgroundImage;

        // Automatically change playing property based on video events
        video.onplay  = _ => this.playing = true;
        video.onpause = _ => this.playing = false;

        video.ontimeupdate = _ => this.updateTime();
        video.onmousemove  = _ => this.onVideoMouseMove();

        // Goto next module when the video ends
        video.onended = _ => this.next();

        if (this.question.fullscreen) {
            video.classList.add('fullscreen');
        } else {
            video.classList.remove('fullscreen');
        }
    },
    destroyed() {
        video.onplay = undefined;
        video.onpause = undefined;
        video.onmousemove = undefined;
        video.ontimeupdate = undefined;
    }
}
</script>