import Segment from './Segment';

export default class FunctionSegment extends Segment {
    /** @type {Object[]} */
    functions;

    constructor(...functions) {
        super(undefined, [], 0);

        this.functions = functions.flat();
    }

    /** @override */
    get status() {
        return { ignored: true };
    }

    *load() {
        for (const func of this.functions) {
            yield func;
        }

        yield { type: 'next' }
    }
}