<template>
    <!-- Checkbox -->
    <validation-provider v-if="isCheckbox" class="validation-provider" tag="div" :name="name" :rules="rules" v-slot="{ errors }">
        <label :for="name" class="checkbox" :class="{'invalid': errors[0]}">
            <label :for="name"><slot>{{name}}</slot></label>
            <input :id="name" :name="name" :type="type" v-model="model"/> 
            <span class="checkmark"><svgCheckmark /></span>
        </label>
        <span class="validation-error" v-if="errors[0]">{{ errors[0] }}</span>
    </validation-provider>

    <!-- Radio -->
    <validation-provider v-else-if="isRadio" class="validation-provider" tag="div" :name="name" :rules="rules" v-slot="{ errors }">
        <label v-for="option in options" :key="option.title" class="input-label" :class="{'invalid': errors[0]}">
            <input :name="name" :type="type" v-model="model" :value="option.key" class="radio"><span class="radio-label">{{option.title}}</span>
        </label>
        <span class="validation-error" v-if="errors[0]">{{ errors[0] }}</span>
    </validation-provider>

    <!-- Textarea -->
    <validation-provider v-else-if="isTextArea" class="validation-provider" tag="div" :name="name" :rules="rules" v-slot="{ errors }">
        <label class="input-label" v-if="name" :for="name"><slot>{{label || name}}</slot></label>
        <textarea :id="name" :name="name" v-model="model" :disabled="disabled" :placeholder="placeholder" :rows="rows" :cols="cols" :class="{'invalid': errors[0]}"/>
        <span class="validation-error" v-if="errors[0]">{{ errors[0] }}</span>
    </validation-provider>

    <!-- Password -->
    <validation-provider v-else-if="isPassword" class="validation-provider" tag="div" :name="name" :rules="rules" v-slot="{ errors }">
        <label class="input-label" v-show="showlabel" :for="name"><slot>{{name}}</slot></label>
        <div class="input-password-wrapper">
            <input :id="name" :name="name"  :type="showPassword ? 'text' : 'password'" v-model="model" :placeholder="placeholder" class="input-password" :class="{'invalid': errors[0]}"/> 
            <component :is="showPassword ? icons.showPassword : icons.hidePassword"  class="input-toggle-password" @click="showPassword = !showPassword"></component>
        </div>
        <span class="validation-error" v-if="errors[0]">{{ errors[0] }}</span>
    </validation-provider>

    <!-- Other -->
    <validation-provider v-else class="validation-provider" tag="div" :name="label || name" :rules="rules" v-slot="{ errors }">
        <label class="input-label" v-show="showlabel" :for="name"><slot>{{label || name}}</slot></label>
        <input :id="name" :name="name" :type="type" v-model="model" v-on="listeners" v-bind="$attrs" :disabled="disabled" :placeholder="placeholder" :class="{'invalid': errors[0]}"/> 
        <span class="validation-error" v-if="errors[0]">{{ errors[0] }}</span>
    </validation-provider>
</template>

<script>

export default {
    props: {
        name: String,
        rules: String,
        type: String,
        options: Array,
        label: String,
        value: undefined,
        placeholder: {type: String, default: null},
        disabled: {type: Boolean, default: false},
        showlabel: {type: Boolean, default: true},

        // textarea options
        cols: Number,
        rows: Number,
    },
    data: () => ({
        showPassword: false,
        videoURL: null,
        thumbnailData: null,
        imageURL: null,
        cropper: null,
        icons: Object.freeze({
            showPassword: require('@/assets/svg/invisible.svg?inline'),
            hidePassword: require('@/assets/svg/visibility.svg?inline'),
        })
    }),
    components: {
      svgCheckmark: require('@/assets/svg/checkmark.svg?inline')
    },
    computed: {
        model: {
            get() { return this.value },
            set(v) { this.$emit('input', v) }
        },        
        listeners() {
            return Object.keys(this.$listeners)
                .filter(x => x !== 'input')
                .reduce((o, k) => Object.assign(o, { [k]: this.$listeners[k] }), {});
        },

        isCheckbox() { return this.type === 'checkbox' },
        isRadio() { return this.type === 'radio' },
        isTextArea() { return this.type === 'textarea' },
        isPassword() { return this.type === 'password' },

        placeholderFormat() {
            console.log("Hiero")
            return this.placeholder == null && this.searchable ? 'Zoeken' : this.placeholder;
        },
    }
}
</script>