<template>
    <div class="light-gray-bg">
        <svgLogo class="logo-small" />
        <module-container :question="question" :locked="!finished" :hidden="!correct" :back="useImageAnswers" @prev="prev" @next="submitChoice">
            <div class="wrapper-choices">
                <transition name="slideup" mode="out-in">
                    <div key="1" v-if="useImageAnswers" class="choices-images">
                        <div :key="choice.id" v-for="choice in choices" class="choice" :class="answerStyle(choice)" @click="canAnswer && makeChoice(choice)">
                            <a class="choice-image" v-if="choice.image">
                                <img :src="choice.image" />
                            </a>
                        </div>
                    </div>

                    <div key="2" v-else-if="!correct && finished" id="popup-correct-answer">
                        <h3>Helaas</h3>
                        <div v-html="question.explanation.text"></div>
                        <video-player v-if="question.explanation.image" :src="question.explanation.image"></video-player>
                        <button @click.prevent="next" class="align-center btn round green">Verder</button>
                    </div>

                    <div key="3" v-else-if="specialTelephoneCase" class="telephone-wrapper">
                        <div class="telephone">
                            <div class="text" v-html="specialTelephoneCasePrefix"/>
                            <input v-model="inputAnswer" :maxlength="TELEPHONECASE_DIGITS" @keypress="filterNumbers($event)" @input="checkFinished" />
                        </div>
                    </div>

                    <div key="4" v-else class="choices-text" :class="'col-' + question.itemsPerLine">
                        <div class="choice" :key="choice.id" v-for="(choice, i) in choices" :class="answerStyle(choice)" @click="canAnswer && makeChoice(choice)" >
                            <div class="label-wrapper">
                                <span class="label">{{ getLabelFromIndex(i) }}</span>
                            </div>
                            <span class="text" v-html="choice.text"/>
                        </div>
                    </div>
                </transition>
            </div>
        </module-container>
    </div>
</template>

<style lang="scss">
@import '~@/scss/variables';
@import '~@/scss/fonts';

.MultipleChoiceModule {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .question {
        .title {
            width: 100% !important;
        }
    }

    .wrapper-choices {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: center;

        .telephone-wrapper {
            background: $blue-color;
            color: $back;
            width: 100%;
            border-radius: 0.6em;
            margin-top: 5vh;

            .telephone {
                width: max-content;
                margin: 0 auto;
                padding: 1em;
            }
        }

        .choices-images {
            margin-top: 1em;
            align-self: center;
            column-count: 5;

            @media (max-width: 1200px) {
                column-count: 4;
            }

            gap: 1em;
            max-width: 5 * 8em;

            .choice {
                position: relative;

                img {
                    width: 100%;
                    height: auto;
                    opacity: 0.4;
    
                    &:hover {
                        opacity: 1;
                    }
                }
    
                &.incorrect {
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        background: url(~@/assets/svg/incorrect.svg) top right no-repeat;
                        background-size: cover;
                        width: 3vw;
                        height: 3vw;
                        min-width: 30px;
                        min-height: 30px;
                        max-width: 50px;
                        max-height: 50px;
                    }

                    img {
                        opacity: 1;
                    }
                }

                &.correct {
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        background: url(~@/assets/svg/correct.svg) top right no-repeat;
                        background-size: cover;
                        width: 3vw;
                        height: 3vw;
                        min-width: 30px;
                        min-height: 30px;
                        max-width: 50px;
                        max-height: 50px;
                    }

                    img {
                        opacity: 1;
                    }
                }
            }
        }

        .choices-text {
            margin-top: 5vh;
            display: grid;
            width: 100%;
            grid-template-columns: 50% 50%;

            &.col-1 {
                grid-template-columns: auto;
            }
            .choice {
                margin: 0.5vh 1em;
                cursor: pointer;
                color: white;
                background-color: $blue-color;
                border-radius: 0.6em;
                border: none;
                padding: 0.5vh 0.7vw;
                font-weight: bold;
                text-align: left; 
                display: flex;
                flex-direction: row;
                align-items: center;

                @include no-select();
                @include font-tiny(0.8);

                .label-wrapper {
                    margin: 0.3em 0;

                    .label {
                        color: $blue-color;
                        background-color: white;
                        padding: 0.5em 0.8em;
                        border-radius: 0.5em;
                        margin: 0 0.3em 0 -1.5em;
                        border: 0.2em $blue-color solid;
                    }
                }
                .text {
                    margin: 2%;
                    min-width: 4em;
                    line-height: 1.5em;
                }
            }

            .choice.active {

                .label {
                    color:  white;
                    background-color: $green-color;
                    border: 0.2em $green-color solid;
                }
            }
        }

        #popup-correct-answer {
            background: $blue-color;
            color: $back;
            padding: 0.5em 10em 1.5em 10em;
            padding-left: min(10em, 7vw);
            padding-right: min(10em, 7vw);
            border-radius: 1em;
            @include font-tiny(0.8);
            width: 100%;

            .btn {
                margin-top: 2em;
            }
        }

        @media (max-width: map-get($breakpoints, 'medium')) {
            .choices-text {
                width: 100%;
                grid-template-columns: auto;
                margin:  1em 0;

                .choice {
                    width: 95%;
                }
            }
        }
    }
}

</style>

<script>
import base from './base';
import ModuleContainer from './ModuleContainer';
import VideoPlayer from '../VideoPlayer.vue';

const TELEPHONECASE_DIGITS = 4;

export default {
    name: 'MultipleChoiceModule',
    data: () => ({
        canAnswer: true,
        selected: [],
        finished: false,
        correct: true,
        inputAnswer: ''
    }),
    components: {
        ModuleContainer,
        VideoPlayer,
        svgLogo: require('@/assets/svg/logo.svg?inline')
    },
    computed: {
        choices() { return this.question.choices; },
        specialTelephoneCase() { return this.question.choices.length == 1; },
        specialTelephoneCasePrefix() { return this.question.choices[0].text.substring(0, this.question.choices[0].text.length - TELEPHONECASE_DIGITS)},
        specialTelephoneCaseRequired() { return this.question.choices[0].text.substring(this.question.choices[0].text.length - TELEPHONECASE_DIGITS)},
        useImageAnswers() { return this.question.useImageAnswers; },
        TELEPHONECASE_DIGITS() { return TELEPHONECASE_DIGITS; }
    },
    methods: {

        filterNumbers: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },

        answerStyle(choice) {
            let style = {};

            if (this.selected.indexOf(choice.id) === -1) return;

            // direct result
            if (this.question.useImageAnswers) {
                if (choice.isCorrect) {
                    return { 'correct': true };
                }
                else {
                    return { 'incorrect': true };
                }
            }

            return { 'active': true };
        },

        makeChoice(choice) {

            if (!this.question.multiple) {
                this.selected = [choice.id];

                if (!this.question.useImageAnswers) {
                    this.finished = true;
                }
                return;
            }

            // handle multiple selection
            let index = this.selected.indexOf(choice.id);
            if (index === -1) {
                // select toggle
                this.selected.push(choice.id);

                // timed deselect if incorrect
                if (this.question.useImageAnswers && !choice.isCorrect) {
                    setTimeout(() => {
                        let lastIndex = this.selected.indexOf(choice.id);
                        this.selected.splice(lastIndex, 1);
                        this.checkFinished();
                    }, 1500);
                }
            } else {
                // manual deselect toggle
                if (!this.question.useImageAnswers) {
                    this.selected.splice(index, 1);
                }
            }

            this.checkFinished();
        },

        checkFinished()
        {
            // special telephone case handeling
            if (this.specialTelephoneCase) {
                this.finished = this.inputAnswer.length == TELEPHONECASE_DIGITS;
                if (this.inputAnswer == this.specialTelephoneCaseRequired) {
                    this.selected.push(this.question.choices[0].id);
                } else {
                    this.selected.splice(0, 1);
                }
                return;
            }

            // direct result
            if (this.question.useImageAnswers) {
                this.finished = this.question.choices.every((c) => c.isCorrect == (this.selected.indexOf(c.id) !== -1) );
                return;
            }

            this.finished = this.selected.length > 0;
        },

        async submitChoice()
        {
            await this.message('save-answer', { 
                questionId: this.question.id,
                answerParts: this.choices.map(c => ({
                    answerId: c.id,
                    answerPart: this.selected.indexOf(c.id) !== -1
                }))
            });

            // check if answer is correct
            // if not, then show popup with correct answer
            if (this.question.explanation) {
                this.correct = this.question.choices.every((c) => c.isCorrect == (this.selected.indexOf(c.id) !== -1));

                if(!this.correct) {
                    return;
                }
            }

            this.next();
        },

        getLabelFromIndex(i) {
            return String.fromCharCode('A'.charCodeAt() + i);
        }
    },
    extends: base()
}
</script>