import VueRouter from 'vue-router'
import Auth from './services/Auth'
//import store from './store';

import Login from './components/Login.vue'
import Landing from './components/Landing.vue';
import Assessment from './components/Assessment.vue';
import AssessmentResult from './components/AssessmentResult.vue';

const routes = [
    // Anonymous pages
    {
        name: "landing",
        path: "/",
        query: { debug: "false" },
        component: Landing,
        meta: {
            allowAnonymous: true,
            view: 'page'
        }
    },
    {
        name: "login",
        path: "/login",
        component: Login,
        props: true,
        meta: {
            allowAnonymous: true,
            view: 'page'
        }
    },
    // {
    //     name: "forgotpassword",
    //     path: "/forgotpassword",
    //     component: ForgotPassword,
    //     meta: {
    //         allowAnonymous: true,
    //         view: 'pop'
    //     }
    // },
    // {
    //     name: "resetpassword",
    //     path: "/resetpassword",
    //     component: ResetPassword,
    //     props: (route) => ({
    //         email: route.query.email,
    //         token: route.query.token,
    //     }),
    //     beforeEnter: (to, from, next) => {
    //         if (!to.query.email || !to.query.token) {
    //             next(from);
    //         } else {
    //             next();
    //         }
    //     },
    //     meta: {
    //         allowAnonymous: true,
    //         view: 'pop'
    //     }
    // },    

    // Authenticated pages

    {
        name: 'assessment',
        path: '/assessment/:code',
        component: Assessment,
        props: true,
        meta: {
            view: 'page-no-nav'
        },
    },
    {
        name: 'assessment-result',
        path: '/assessment/:code/result',
        component: AssessmentResult,
        props: true,
        meta: {
            view: 'page-no-nav'
        },
    }    
];

const router = new VueRouter({
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let defaultPos = { x: 0, y: 0 };

                if (savedPosition) {
                    resolve(savedPosition);
                } else if (to.hash) {
                    resolve({
                        selector: to.hash,
                        behavior: "smooth",
                    });
                } else  {
                    resolve(defaultPos);
                }
            }, 650);
        });
    },
});

router.beforeEach((to, from, next) => {

    if (to.meta?.location) {
        document.location = to.meta?.location;
        return;
    }

    if (Auth.isLoggedIn() || to.meta?.allowAnonymous) {
        next();
    } else {
        next(from);
    }
})

export default router
